import CircleIcon from "@mui/icons-material/Circle";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
} from "@mui/material";
import { useMemo } from "react";
import { Account } from "../../hooks/accounts/useLoadAccounts";
import { ScheduledExpense } from "../../hooks/expenses/useLoadExpensesSchedule";
import { Income } from "../../hooks/incomes/useLoadIncomes";
import { statusColorMap } from "../../theme";

interface Props {
  accounts: Account[];
  expenseScheduleData: ScheduledExpense[];
  incomeScheduleData: Income[];
}

type AccountStatus = "good" | "warning" | "danger";

function AccountsBreakdown({
  accounts,
  expenseScheduleData,
  incomeScheduleData,
}: Props) {
  const theme = useTheme();

  const getStatusColorFrom = (status: AccountStatus) => {
    switch (status) {
      case "good":
        return statusColorMap.good;
      case "warning":
        return statusColorMap.warning;
      case "danger":
        return statusColorMap.danger;
    }
  };

  const accountsWithDiff = useMemo(() => {
    const getStatusFrom = (diff: number): AccountStatus => {
      if (diff > 50) {
        return "good";
      } else if (diff < 0) {
        return "danger";
      } else {
        return "warning";
      }
    };

    return accounts.map((account) => {
      const remainingExpenses = expenseScheduleData.reduce(
        (acc, { accountId, isPaid, amount }) =>
          accountId === account.id && !isPaid ? acc + amount || 0 : acc,
        0
      );

      const income = incomeScheduleData.reduce(
        (acc, { accountId, amount }) =>
          accountId === account.id ? acc + amount || 0 : acc,
        0
      );

      const diff = account.amount - remainingExpenses + income;

      return {
        ...account,
        remainingExpenses: parseFloat(remainingExpenses.toFixed(2)),
        income: parseFloat(income.toFixed(2)),
        diff: parseFloat(diff.toFixed(2)),
        status: getStatusFrom(diff),
      };
    });
  }, [accounts, expenseScheduleData, incomeScheduleData]);

  return (
    <TableContainer
      sx={{
        backgroundColor: theme.custom.main,
        boxShadow: "none",
        color: theme.custom.text,
      }}
      component={Paper}
    >
      <Table sx={{ color: theme.custom.text }} aria-label="simple table">
        <TableHead sx={{ color: theme.custom.text }}>
          <TableRow>
            <TableCell sx={{ color: theme.custom.text }}>
              Account name
            </TableCell>
            <TableCell sx={{ color: theme.custom.text }} align="right">
              Balance
            </TableCell>
            <TableCell sx={{ color: theme.custom.text }} align="right">
              Income due
            </TableCell>
            <TableCell sx={{ color: theme.custom.text }} align="right">
              Expenses remaining
            </TableCell>
            <TableCell sx={{ color: theme.custom.text }} align="right">
              Closing balance
            </TableCell>
            <TableCell sx={{ color: theme.custom.text }} align="center">
              Status
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {accountsWithDiff.map((row) => (
            <TableRow
              key={row.name}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
              }}
            >
              <TableCell
                sx={{ color: theme.custom.text }}
                component="th"
                scope="row"
              >
                {row.name}
              </TableCell>
              <TableCell sx={{ color: theme.custom.text }} align="right">
                £{row.amount}
              </TableCell>
              <TableCell sx={{ color: theme.custom.text }} align="right">
                £{row.income}
              </TableCell>
              <TableCell sx={{ color: theme.custom.text }} align="right">
                £{row.remainingExpenses}
              </TableCell>
              <TableCell sx={{ color: theme.custom.text }} align="right">
                £{row.diff}
              </TableCell>
              <TableCell
                sx={{ color: getStatusColorFrom(row.status) }}
                align="center"
              >
                <CircleIcon
                  fontSize="small"
                  sx={{ display: "flex", margin: "auto" }}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default AccountsBreakdown;
