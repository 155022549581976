import { useCallback, useEffect, useState } from "react";
import axiosInstance from "../../utils/axiosConfig";

export interface UserPreferences {
  userId: string;
  from: string;
  to: string;
  themeId: string;
}

const useLoadUserPreferences = ({ userId }: { userId: string }) => {
  const [data, setData] = useState<UserPreferences | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const fetchData = useCallback(async () => {
    try {
      const response = await axiosInstance.get("/api/user-preferences", {
        params: { userId },
      });
      setData(response.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("Failed to fetch data.");
    } finally {
      setLoading(false);
    }
  }, [userId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return { data, error, loading, refetch: fetchData };
};

export default useLoadUserPreferences;
