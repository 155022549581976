import { useCallback, useEffect, useState } from "react";
import axiosInstance from "../../utils/axiosConfig";

export interface ChartSeries {
  label: string;
  data: BalanceTimeSeries[];
}

export interface BalanceTimeSeries {
  in: number;
  out: number;
  date: string;
  balance: number;
}

const useLoadBalancesTimeSeries = ({
  from,
  to,
  accountIds,
  userId,
}: {
  from: string | null;
  to: string | null;
  accountIds: string[] | null;
  userId: string;
}) => {
  const [data, setData] = useState<ChartSeries[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const fetchData = useCallback(async () => {
    try {
      const response = await axiosInstance.get<ChartSeries[]>(
        "/api/balances/time-series",
        {
          params: {
            from,
            to,
            accountIds: accountIds?.length ? accountIds : null,
            userId,
          },
        }
      );
      setData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("Failed to fetch data.");
    } finally {
      setLoading(false);
    }
  }, [from, to, accountIds, userId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    data,
    error,
    loading,
    refetch: fetchData,
  };
};

export default useLoadBalancesTimeSeries;
