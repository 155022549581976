import { useCallback, useState } from "react";
import axiosInstance from "../../utils/axiosConfig";
import { Expense } from "./useLoadExpenses";

const useCreateExpense = () => {
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const mutation = useCallback(
    async (expense: Omit<Expense, "id" | "createdAt" | "accountName">) => {
      try {
        setLoading(true);
        await axiosInstance.post<Expense[]>("/api/expenses", expense);
      } catch (error) {
        console.error("Error posting data:", error);
        setError("Failed to post data.");
      } finally {
        setLoading(false);
      }
    },
    []
  );

  return { error, loading, mutation };
};

export default useCreateExpense;
