import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { forwardRef, useMemo } from "react";
import { Account } from "../../hooks/accounts/useLoadAccounts";
import { Alert, AlertMap, AlertType } from "../../hooks/expenses/useLoadAlerts";
import { ScheduledExpense } from "../../hooks/expenses/useLoadExpensesSchedule";

interface Props {
  alertMap: AlertMap;
  alertType: AlertType | null;
  onClose: () => void;
  onUpdateBalance: (account: Account, alertType: AlertType) => void;
  onMarkAsPaid: (expense: ScheduledExpense) => void;
}

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

function AlertCTA({
  alert,
  onUpdateBalance,
  onMarkAsPaid,
}: {
  alert: Alert;
  onUpdateBalance: (account: Account, alertType: AlertType) => void;
  onMarkAsPaid: (expense: ScheduledExpense) => void;
}) {
  const ctaLabel = (status: AlertType) => {
    if (status === AlertType.OverdueExpense) {
      return "Mark as paid";
    } else {
      return "Update balance";
    }
  };

  // todo: update all balances for the total balance alert
  const handler = (status: AlertType) => {
    if (status === AlertType.OverdueExpense) {
      return onMarkAsPaid(alert.scheduledExpense!);
    } else {
      return onUpdateBalance(alert.account!, status);
    }
  };

  if (alert.alertType !== AlertType.OverdueExpense && !alert.account) {
    // only show the update balance button if the cta is Update balance and account is present
    return null;
  }

  if (alert.alertType === AlertType.OverdueExpense && !alert.scheduledExpense) {
    // only show the mark as paid button if the cta is Mark as paid and scheduledExpense is present
    return null;
  }

  return (
    <Button
      size="small"
      variant="contained"
      color="primary"
      onClick={() => handler(alert.alertType)}
      sx={{ fontSize: "0.75rem", lineHeight: "1.25" }}
    >
      {ctaLabel(alert.alertType)}
    </Button>
  );
}

function AlertDialog({
  alertMap,
  alertType,
  onClose,
  onUpdateBalance,
  onMarkAsPaid,
}: Props) {
  const theme = useTheme();

  const filteredAlerts = useMemo((): Alert[] => {
    if (!alertType) {
      return [
        ...alertMap.overdueExpense,
        ...alertMap.zeroTotalBalance,
        ...alertMap.zeroAccountBalance,
        ...alertMap.expenseAtRisk,
      ];
    }

    switch (alertType) {
      case "overdueExpense":
        return alertMap.overdueExpense;
      case "zeroTotalBalance":
        return alertMap.zeroTotalBalance;
      case "zeroAccountBalance":
        return alertMap.zeroAccountBalance;
      case "expenseAtRisk":
        return alertMap.expenseAtRisk;
    }

    return [];
  }, [
    alertMap.expenseAtRisk,
    alertMap.overdueExpense,
    alertMap.zeroAccountBalance,
    alertMap.zeroTotalBalance,
    alertType,
  ]);

  return (
    <Dialog
      open={true}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      aria-describedby="alert-dialog-slide-description"
      maxWidth="lg"
      fullWidth
      sx={{ ".MuiPaper-root": { backgroundColor: theme.custom.dialogBg } }}
    >
      <DialogTitle>
        {filteredAlerts.length}{" "}
        {filteredAlerts.length === 1 ? `Alert` : `Alerts`}
      </DialogTitle>
      <DialogContent dividers>
        {/* TODO: 1 table component */}
        <TableContainer
          sx={{
            backgroundColor: theme.custom.dialogBg,
            boxShadow: "none",
          }}
          component={Paper}
        >
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left">Title</TableCell>
                <TableCell align="left">Description</TableCell>
                <TableCell width={"15%"} align="right">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredAlerts.map((row, i) => (
                <TableRow
                  key={`${row.alertType}-${i}`}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell align="left">{row.title}</TableCell>
                  <TableCell align="left">{row.message}</TableCell>
                  <TableCell align="right">
                    <AlertCTA
                      alert={row}
                      onMarkAsPaid={onMarkAsPaid}
                      onUpdateBalance={onUpdateBalance}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

export default AlertDialog;
