import { Button, Grid, Typography } from "@mui/material";
import notFoundImage from "../images/404.png";
import { useAuthContext } from "../context/AuthContext";

function NotFoundPage() {
  const { user } = useAuthContext();

  return (
    <Grid
      container
      spacing={2}
      minHeight={"100vh"}
      display="flex"
      alignItems="center"
      textAlign="center"
    >
      <Grid item xs={12}>
        <img src={notFoundImage} alt="404" height={"60%"} />
        <Typography mt={2} mb={4} variant="h5">
          Page Not Found
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          href={user ? "/dashboard" : "/"}
        >
          {user ? "Go to dashboard" : "Go back home"}
        </Button>
      </Grid>
    </Grid>
  );
}

export default NotFoundPage;
