import { useCallback, useState } from "react";
import axiosInstance from "../../utils/axiosConfig";

const useDeleteAccount = () => {
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const mutation = useCallback(async (accountId: string) => {
    try {
      setLoading(true);
      await axiosInstance.delete("/api/account", { data: { accountId } });
    } catch (error) {
      console.error("Error deleting data:", error);
      setError("Failed to delete data.");
    } finally {
      setLoading(false);
    }
  }, []);

  return { error, loading, mutation };
};

export default useDeleteAccount;
