import {
  Box,
  Card,
  CardContent,
  Switch,
  Typography,
  useTheme,
} from "@mui/material";
import {
  ChartsReferenceLine,
  LineChart,
  areaElementClasses,
  useDrawingArea,
  useYScale,
} from "@mui/x-charts";
import dayjs from "dayjs";
import { useMemo } from "react";
import { ChartSeries } from "../../hooks/balances/useLoadBalancesTimeSeries";
import { statusColorMap } from "../../theme";

interface Props {
  chartData: ChartSeries[];
  isBreakdown: boolean;
  onToggleBreakdown: () => void;
}

const getMin = (chartData: ChartSeries[]) => {
  const res = Math.min(
    0,
    ...chartData.flatMap((d) => d.data.map((d) => d.balance))
  );

  return res < 0 ? res - 50 : 0;
};

type ColorSwichProps = {
  threshold: number;
  color1: string;
  color2: string;
  id: string;
};

function ColorSwich({ threshold, color1, color2, id }: ColorSwichProps) {
  const { top, height, bottom } = useDrawingArea();
  const svgHeight = top + bottom + height;

  const scale = useYScale();
  const y0 = scale(threshold);
  const off = y0 !== undefined ? y0 / svgHeight : 0;

  return (
    <defs>
      <linearGradient
        id={id}
        x1="0"
        x2="0"
        y1="0"
        y2={`${svgHeight}px`}
        gradientUnits="userSpaceOnUse" // Use the SVG coordinate instead of the component ones.
      >
        <stop offset={off} stopColor={color1} stopOpacity={1} />
        <stop offset={off} stopColor={color2} stopOpacity={1} />
      </linearGradient>
    </defs>
  );
}

function DailyBalanceCard({
  chartData,
  isBreakdown,
  onToggleBreakdown,
}: Props) {
  const theme = useTheme();

  const isSingleSeries = chartData.length === 1;
  const min = useMemo(() => getMin(chartData), [chartData]);

  return (
    <Card
      variant="outlined"
      sx={{
        backgroundColor: theme.custom.main,
        borderColor: theme.custom.border,
        height: "100%",
      }}
    >
      {chartData[0]?.data.length ? (
        <>
          <CardContent>
            <Box display="flex">
              <Typography
                gutterBottom
                sx={{
                  color: theme.custom.text,
                  fontSize: 14,
                }}
              >
                Daily balance
              </Typography>
              <Box
                sx={{
                  marginLeft: "auto",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    color: theme.custom.text,
                    fontSize: 12,
                  }}
                >
                  Show breakdown
                </Typography>
                <Switch
                  checked={isBreakdown}
                  onChange={onToggleBreakdown}
                  color="secondary"
                />
              </Box>
            </Box>
            <LineChart
              height={300}
              xAxis={[
                {
                  scaleType: "point",
                  data: chartData[0].data.flatMap((d) => d.date),
                  valueFormatter(value, context) {
                    return dayjs(value).format("DD/MM");
                  },
                },
              ]}
              series={chartData.map(({ label, data }) => ({
                data: data.map((d) => d.balance),
                label,
                showMark: false,
                curve: "monotoneX",
                ...(isSingleSeries && {
                  area: true,
                  color: theme.custom.accent,
                }),
              }))}
              yAxis={[
                {
                  min,
                  max:
                    Math.max(
                      ...chartData.flatMap((d) => d.data.map((d) => d.balance))
                    ) + 50,
                },
              ]}
              slotProps={{
                legend: { hidden: true },
              }}
              sx={{
                ".MuiAreaElement-root": { opacity: 0.1 },
                ".MuiChartsAxis-tickLabel": {
                  fill: `${theme.custom.text} !important`,
                },
                ".MuiChartsAxis-line": {
                  stroke: `${theme.custom.text} !important`,
                },
                ".MuiChartsAxis-tick": {
                  stroke: `${theme.custom.text} !important`,
                },
                [`& .${areaElementClasses.root}`]: {
                  fill: "url(#swich-color-id-1)",
                },
              }}
            >
              {min < 0 && (
                <ChartsReferenceLine
                  y={0}
                  lineStyle={{
                    stroke: statusColorMap.danger,
                    strokeDasharray: "10 5",
                  }}
                />
              )}

              <ColorSwich
                color1={theme.custom.accent!}
                color2={statusColorMap.danger}
                threshold={0}
                id="swich-color-id-1"
              />
            </LineChart>
          </CardContent>
        </>
      ) : null}
    </Card>
  );
}

export default DailyBalanceCard;
