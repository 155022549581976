import BloodtypeIcon from "@mui/icons-material/Bloodtype";
import { Box, Grid, Typography } from "@mui/material";
import notFoundImage from "../images/moon.png";
import { darkBgColor } from "../theme";
import SignInForm from "../components/home/SignInForm";

function HomePage() {
  return (
    <>
      <Box
        position="fixed"
        display={"flex"}
        alignItems={"center"}
        sx={{ marginTop: "13px", paddingLeft: "12px" }}
      >
        <BloodtypeIcon
          display={"flex"}
          sx={{
            marginRight: 1,
            marginLeft: 1,
            display: "flex",
            color: "white",
            alignSelf: "center",
          }}
        />
        <Typography
          variant="h6"
          display={"flex"}
          sx={{
            height: "64px",
            alignItems: "center",
            width: "192px",
            display: "flex",
            color: "white",
          }}
        >
          Account Dracula
        </Typography>
      </Box>
      <Grid container sx={{ margin: 0 }} minHeight="100vh">
        <Grid
          item
          xs={7}
          sx={{ backgroundColor: darkBgColor }}
          display={"flex"}
          alignItems={"center"}
          textAlign={"center"}
        >
          <div className="image-container">
            <img src={notFoundImage} alt="moon" className="homepage-image" />
          </div>
        </Grid>
        <Grid
          item
          xs={5}
          display={"flex"}
          alignItems={"center"}
          textAlign={"center"}
        >
          <SignInForm />
        </Grid>
      </Grid>
    </>
  );
}

export default HomePage;
