import CreditCardIcon from "@mui/icons-material/CreditCard";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import WalletIcon from "@mui/icons-material/Wallet";
import InsightsIcon from "@mui/icons-material/Insights";
import LogoutIcon from "@mui/icons-material/Logout";
import { BottomNavigation, BottomNavigationAction, Paper } from "@mui/material";
import { NavLink, useNavigate } from "react-router-dom";
import useSignOut from "../../hooks/authentication/useSignOut";
import { useAuthContext } from "../../context/AuthContext";

function BottomNav() {
  const { mutation: signOut } = useSignOut();
  const navigate = useNavigate();

  const { setUser } = useAuthContext();

  const handleSignOut = async () => {
    await signOut();
    setUser(null);
    navigate("/");
  };

  return (
    <Paper
      sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
      elevation={3}
    >
      <BottomNavigation
        showLabels
        value={0}
        // todo: highlight active
      >
        <NavLink to="/">
          <BottomNavigationAction label="Dashboard" icon={<InsightsIcon />} />
        </NavLink>
        <NavLink to="/accounts">
          <BottomNavigationAction label="Accounts" icon={<CreditCardIcon />} />
        </NavLink>
        <NavLink to="/incomes">
          <BottomNavigationAction label="Income" icon={<WalletIcon />} />
        </NavLink>
        <NavLink to="/expenses">
          <BottomNavigationAction
            label="Expenses"
            icon={<ShoppingCartCheckoutIcon />}
          />
        </NavLink>
        <BottomNavigationAction
          label="Logout"
          icon={<LogoutIcon onClick={handleSignOut} />}
        />
      </BottomNavigation>
    </Paper>
  );
}

export default BottomNav;
