import { useCallback, useState } from "react";
import axiosInstance from "../../utils/axiosConfig";
import { Income } from "./useLoadIncomes";

const useCreateIncome = () => {
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const mutation = useCallback(
    async (income: Omit<Income, "id" | "accountName" | "createdAt">) => {
      try {
        setLoading(true);
        await axiosInstance.post<Income[]>("/api/incomes", income);
      } catch (error) {
        console.error("Error posting data:", error);
        setError("Failed to post data.");
      } finally {
        setLoading(false);
      }
    },
    []
  );

  return { error, loading, mutation };
};

export default useCreateIncome;
