import { useCallback, useEffect, useState } from "react";
import axiosInstance from "../../utils/axiosConfig";
import { Account } from "../accounts/useLoadAccounts";
import { ScheduledExpense } from "./useLoadExpensesSchedule";

export enum AlertType {
  OverdueExpense = "overdueExpense",
  ZeroTotalBalance = "zeroTotalBalance",
  ZeroAccountBalance = "zeroAccountBalance",
  ExpenseAtRisk = "expenseAtRisk",
}

export interface Alert {
  alertType: AlertType;
  title: string;
  value: number;
  message: string;
  scheduledExpense: ScheduledExpense | null;
  account: Account | null;
}

export interface AlertMap {
  alertCount: number;
  overdueExpense: Alert[];
  zeroTotalBalance: Alert[];
  zeroAccountBalance: Alert[];
  expenseAtRisk: Alert[];
}

const useLoadAlerts = ({
  from,
  to,
  alertType,
  userId,
}: {
  from: string | null;
  to: string | null;
  alertType?: AlertType;
  userId: string;
}) => {
  const [data, setData] = useState<AlertMap>({
    alertCount: 0,
    overdueExpense: [],
    zeroTotalBalance: [],
    zeroAccountBalance: [],
    expenseAtRisk: [],
  });
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const fetchData = useCallback(async () => {
    try {
      const response = await axiosInstance.get<AlertMap>("/api/alerts", {
        params: {
          from,
          to,
          alertType,
          userId,
        },
      });
      setData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("Failed to fetch data.");
    } finally {
      setLoading(false);
    }
  }, [alertType, from, to, userId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return { data, error, loading, refetch: fetchData };
};

export default useLoadAlerts;
