import {
  createTheme,
  Theme as MUITheme,
  ThemeOptions,
  ThemeProvider,
} from "@mui/material";
import {
  createContext,
  CSSProperties,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import useThemes, { Theme } from "../hooks/themes/useThemes";
import useLoadUserPreferences from "../hooks/user-preferences/userLoadUserPreferences";
import { useAuthContext } from "./AuthContext";

export const mainColor = "#2c3345";
export const bgColor = "#2e3443";
export const darkBgColor = "#212329";
export const borderColor = "#4e5361";
export const textColor = "#a2a4a7";
export const accentColor = "#7e6792";
export const alertBackgroundColor = "#b0bfe4";
export const statusColorMap = {
  good: "#429442",
  warning: "#947242",
  danger: "#944242",
};

declare module "@mui/material/styles" {
  interface Theme {
    custom: {
      main: CSSProperties["color"];
      accent: CSSProperties["color"];
      text: CSSProperties["color"];
      border: CSSProperties["color"];
      bg: CSSProperties["color"];
      dialogBg: CSSProperties["color"];
    };
  }
  interface ThemeOptions {
    custom?: {
      main: CSSProperties["color"];
      accent: CSSProperties["color"];
      text: CSSProperties["color"];
      border: CSSProperties["color"];
      bg: CSSProperties["color"];
      dialogBg: CSSProperties["color"];
    };
  }
}

export const defaultTheme: Theme = {
  id: "default",
  label: "Default",
  accent: accentColor,
  bg: bgColor,
  border: borderColor,
  dialogBg: alertBackgroundColor,
  main: mainColor,
  text: textColor,
  owner: "default",
};

const makeThemeConfig: (
  theme: Pick<Theme, "accent" | "main" | "text" | "border" | "bg" | "dialogBg">
) => ThemeOptions = ({ main, accent, text, border, bg, dialogBg }) => ({
  palette: {
    primary: {
      light: "#00",
      main: main,
      dark: "#000",
      contrastText: "#fff",
    },
    secondary: {
      light: "#000",
      main: accent,
      dark: main,
      contrastText: text,
    },
  },
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: main,
          borderRight: `1px solid ${border}`,
          padding: "0 20px",
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: "none",
        },
      },
    },
  },
  custom: {
    main,
    accent,
    text,
    border,
    bg,
    dialogBg,
  },
});

const UserThemeContext = createContext<{
  themes: Theme[];
  reloadThemes: () => Promise<void>;
}>({
  themes: [],
  reloadThemes: () => Promise.resolve(),
});

export const UserThemeProvider = ({ children }: { children: JSX.Element }) => {
  const [themeConfig, setThemeConfig] = useState<MUITheme>(
    createTheme(makeThemeConfig(defaultTheme))
  );
  const { user } = useAuthContext();
  const { data: userPreferencesData, refetch: userPreferencesRefetch } =
    useLoadUserPreferences({
      userId: user?.uid || "",
    });
  const { data: themesData, refetch: themesRefetch } = useThemes();

  const updateTheme = useCallback(() => {
    const chosenTheme = () => {
      if (!themesData || !userPreferencesData) {
        return null;
      }

      return themesData.find(
        (theme) => theme.id === userPreferencesData.themeId
      );
    };

    const themeConfig: MUITheme = createTheme(
      makeThemeConfig(chosenTheme() ?? defaultTheme)
    );

    setThemeConfig(themeConfig);
  }, [themesData, userPreferencesData]);

  useEffect(() => {
    updateTheme();
  }, [updateTheme]);

  const reloadThemes = async () => {
    await themesRefetch();
    await userPreferencesRefetch();
    updateTheme();
  };

  return (
    <UserThemeContext.Provider
      value={{ reloadThemes, themes: themesData ?? [] }}
    >
      <ThemeProvider theme={themeConfig}>{children}</ThemeProvider>
    </UserThemeContext.Provider>
  );
};

export const useThemeContext = () => useContext(UserThemeContext);
