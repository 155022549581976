import { Card, CardContent, Typography } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { PieChart } from "@mui/x-charts/PieChart";
import { useDrawingArea } from "@mui/x-charts/hooks";
import * as React from "react";
import { Account } from "../../hooks/accounts/useLoadAccounts";

interface Props {
  data: Account[];
  label: string;
}

const StyledText = styled("text")(({ theme }) => ({
  fill: theme.palette.text.primary,
  textAnchor: "middle",
  dominantBaseline: "central",
  fontSize: 20,
}));

function PieCenterLabel({ children }: { children: React.ReactNode }) {
  const { width, height, left, top } = useDrawingArea();
  const theme = useTheme();

  return (
    <StyledText
      x={left + width / 2}
      y={top + height / 2}
      sx={{ fill: theme.custom.text, fontSize: 30 }}
    >
      {children}
    </StyledText>
  );
}

function AccountsSummaryCard({ data, label }: Props) {
  const theme = useTheme();

  const chartData = React.useMemo(
    () =>
      data
        .map((d) => ({ value: d.amount, label: d.name }))
        .sort((a, b) => a.value - b.value),
    [data]
  );

  const size = {
    width: 255,
    height: 210,
  };

  return (
    <Card
      variant="outlined"
      sx={{
        backgroundColor: theme.custom.main,
        borderColor: theme.custom.border,
        height: "100%",
      }}
    >
      <>
        <CardContent>
          <Typography
            gutterBottom
            sx={{
              color: theme.custom.text,
              fontSize: 14,
            }}
          >
            Account distribution
          </Typography>
          <PieChart
            margin={{ right: 5 }}
            series={[
              {
                data: chartData,
                innerRadius: 70,
                outerRadius: 100,
              },
            ]}
            sx={{ ".MuiPieArc-root": { stroke: theme.custom.text } }}
            colors={[
              // accentColor,
              "#4d194d",
              "#3e1f47",
              "#312244",
              "#272640",
              "#212f45",
              "#1b3a4b",
              "#144552",
              "#0b525b",
              "#065a60",
              "#006466",
            ]}
            slotProps={{
              legend: { hidden: true },
            }}
            {...size}
          >
            <PieCenterLabel>{label}</PieCenterLabel>
          </PieChart>
        </CardContent>
      </>
    </Card>
  );
}

export default AccountsSummaryCard;
