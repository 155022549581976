import { useCallback, useState } from "react";
import axiosInstance from "../../utils/axiosConfig";
import { ScheduledExpense } from "./useLoadExpensesSchedule";

const useCreatePaidExpense = () => {
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const mutation = useCallback(async (expense: ScheduledExpense) => {
    try {
      setLoading(true);
      await axiosInstance.post<ScheduledExpense[]>(
        "/api/paid-expense",
        expense
      );
    } catch (error) {
      console.error("Error creating data:", error);
      setError("Failed to create data.");
    } finally {
      setLoading(false);
    }
  }, []);

  return { error, loading, mutation };
};

export default useCreatePaidExpense;
