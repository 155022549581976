import { useCallback, useEffect, useState } from "react";
import axiosInstance from "../../utils/axiosConfig";

export interface Account {
  id: string;
  name: string;
  amount: number;
  userId: string;
}

const useLoadAccounts = ({ userId }: { userId: string }) => {
  const [data, setData] = useState<Account[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const fetchData = useCallback(async () => {
    try {
      const response = await axiosInstance.get<Account[]>("/api/accounts", {
        params: { userId },
      });
      setData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("Failed to fetch data.");
    } finally {
      setLoading(false);
    }
  }, [userId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return { data, error, loading, refetch: fetchData };
};

export default useLoadAccounts;
