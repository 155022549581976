import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Slide,
  Typography,
  useTheme,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { forwardRef, useState } from "react";
import { Account } from "../../hooks/accounts/useLoadAccounts";

interface Props {
  account: Account;
  editLoading: boolean;
  editError: string | null;
  handleEdit: (accountId: string, patch: Pick<Account, "amount">) => void;
  onClose: () => void;
}

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

function UpdateAccountBalanceDialog({
  account,
  editLoading,
  editError,
  handleEdit,
  onClose,
}: Props) {
  const theme = useTheme();

  const [newBalance, setNewBalance] = useState<number>(account.amount);

  return (
    <Dialog
      open={true}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      aria-describedby="alert-dialog-slide-description"
      maxWidth="sm"
      fullWidth
      sx={{ ".MuiPaper-root": { backgroundColor: theme.custom.dialogBg } }}
    >
      <DialogTitle>Edit balance for {account.name}</DialogTitle>
      <DialogContent dividers>
        <FormControl fullWidth>
          <InputLabel>Amount</InputLabel>
          <OutlinedInput
            startAdornment={<InputAdornment position="start">£</InputAdornment>}
            label="Amount"
            type="number"
            fullWidth
            value={newBalance}
            onChange={(event) => setNewBalance(Number(event.target.value) ?? 0)}
          />
        </FormControl>
        {editError && (
          <Typography variant="caption" color="error">
            {editError}
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="secondary"
          disabled={editLoading}
          onClick={() => handleEdit(account.id, { amount: newBalance })}
        >
          {editLoading ? "Updating balance" : "Update balance"}
        </Button>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

export default UpdateAccountBalanceDialog;
