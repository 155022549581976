import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Slide,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { forwardRef, useState } from "react";
import { Account } from "../../hooks/accounts/useLoadAccounts";
import { Expense } from "../../hooks/expenses/useLoadExpenses";

interface Props {
  expense: Expense;
  accounts: Account[];
  editLoading: boolean;
  editError: string | null;
  handleEdit: (
    id: string,
    patch: Pick<Expense, "accountId" | "amount" | "interval" | "name"> & {
      dayOfWeek: number | null;
      dayOfMonth: number | null;
      date: string | null;
    }
  ) => void;
  onClose: () => void;
}

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

function EditExpenseDialog({
  expense,
  editLoading,
  editError,
  accounts,
  handleEdit,
  onClose,
}: Props) {
  const theme = useTheme();

  const [newExpense, setNewExpense] = useState<
    Pick<Expense, "accountId" | "amount" | "interval" | "name"> & {
      dayOfWeek: number | null;
      dayOfMonth: number | null;
      date: string | null;
    }
  >({
    ...expense,
    dayOfMonth:
      expense.interval === "monthly" ? new Date(expense.date).getDate() : null,
    dayOfWeek: expense.interval === "weekly" ? dayjs(expense.date).day() : null,
    date: ["once", "yearly"].includes(expense.interval) ? expense.date : null,
  });

  return (
    <Dialog
      open={true}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      aria-describedby="alert-dialog-slide-description"
      maxWidth="sm"
      fullWidth
      sx={{ ".MuiPaper-root": { backgroundColor: theme.custom.dialogBg } }}
    >
      <DialogTitle>Edit expense</DialogTitle>
      <DialogContent dividers>
        <FormControl fullWidth sx={{ mb: 4 }} variant="outlined">
          <TextField
            label="Expense name"
            variant="outlined"
            fullWidth
            value={newExpense.name}
            onChange={(event) =>
              setNewExpense({ ...newExpense, name: event.target.value })
            }
          />
        </FormControl>
        <FormControl fullWidth sx={{ mb: 4 }}>
          <InputLabel>Amount</InputLabel>
          <OutlinedInput
            startAdornment={<InputAdornment position="start">£</InputAdornment>}
            label="Amount"
            fullWidth
            value={newExpense.amount}
            type="number"
            onChange={(event) =>
              setNewExpense({
                ...newExpense,
                amount: Number(event.target.value) ?? 0,
              })
            }
          />
        </FormControl>
        <FormControl fullWidth sx={{ mb: 4 }}>
          <InputLabel id="select-interval">Interval</InputLabel>
          <Select
            labelId="select-interval-label"
            id="select-interval"
            value={newExpense.interval}
            label="Interval"
            onChange={(event) =>
              setNewExpense({
                ...newExpense,
                interval: event.target.value as
                  | "yearly"
                  | "monthly"
                  | "weekly"
                  | "once",
              })
            }
          >
            <MenuItem key="once" value="once">
              One off
            </MenuItem>
            <MenuItem key="weekly" value="weekly">
              Weekly
            </MenuItem>
            <MenuItem key="monthly" value="monthly">
              Monthly
            </MenuItem>
            <MenuItem key="yearly" value="yearly">
              Yearly
            </MenuItem>
          </Select>
        </FormControl>
        {newExpense.interval === "weekly" && (
          <FormControl fullWidth sx={{ mb: 4 }}>
            <InputLabel id="select-day-of-week-label">Day of week</InputLabel>
            <Select
              labelId="select-day-of-week-label"
              id="select-day-of-week"
              value={newExpense.dayOfWeek}
              label="Day of week"
              onChange={(event) =>
                setNewExpense({
                  ...newExpense,
                  dayOfWeek: Number(event.target.value) ?? 1,
                })
              }
            >
              {[
                { key: 1, label: "Monday" },
                { key: 2, label: "Tuesday" },
                { key: 3, label: "Wednesday" },
                { key: 4, label: "Thursday" },
                { key: 5, label: "Friday" },
                { key: 6, label: "Saturday" },
                { key: 0, label: "Sunday" },
              ].map(({ key, label }) => (
                <MenuItem key={key} value={key}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {newExpense.interval === "monthly" && (
          <FormControl fullWidth sx={{ mb: 4 }}>
            <InputLabel id="select-day-of-month-label">Day of month</InputLabel>
            <Select
              labelId="select-day-of-month-label"
              id="select-day-of-month"
              value={newExpense.dayOfMonth}
              label="Day of month"
              onChange={(event) =>
                setNewExpense({
                  ...newExpense,
                  dayOfMonth: Number(event.target.value) ?? 1,
                })
              }
            >
              {Array.from({ length: 28 }, (_, i) => i + 1).map((day) => (
                <MenuItem key={day} value={day}>
                  {day}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {newExpense.interval === "yearly" && (
          <FormControl fullWidth sx={{ mb: 4 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                disablePast
                label="Next due date"
                onChange={(value) =>
                  setNewExpense({
                    ...newExpense,
                    date: value ? value.toISOString() : null,
                  })
                }
                value={newExpense.date ? dayjs(newExpense.date) : null}
                sx={{
                  marginRight: "20px",
                  ".MuiOutlinedInput-notchedOutline": {
                    borderColor: theme.custom.border,
                  },
                  ".MuiInputLabel-root": {
                    color: theme.custom.text,
                  },
                  ".MuiOutlinedInput-input": {
                    color: theme.custom.text,
                  },
                  ".MuiSvgIcon-root": {
                    color: theme.custom.text,
                  },
                }}
              />
            </LocalizationProvider>
          </FormControl>
        )}
        {newExpense.interval === "once" && (
          <FormControl fullWidth sx={{ mb: 4 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Date"
                onChange={(value) =>
                  setNewExpense({
                    ...newExpense,
                    date: value ? value.toISOString() : null,
                  })
                }
                value={newExpense.date ? dayjs(newExpense.date) : null}
                sx={{
                  marginRight: "20px",
                  ".MuiOutlinedInput-notchedOutline": {
                    borderColor: theme.custom.border,
                  },
                  ".MuiInputLabel-root": {
                    color: theme.custom.text,
                  },
                  ".MuiOutlinedInput-input": {
                    color: theme.custom.text,
                  },
                  ".MuiSvgIcon-root": {
                    color: theme.custom.text,
                  },
                }}
              />
            </LocalizationProvider>
          </FormControl>
        )}
        <FormControl fullWidth>
          <InputLabel id="select-account-label">Account</InputLabel>
          <Select
            labelId="select-account-label"
            id="select-account"
            value={newExpense.accountId}
            label="Account"
            onChange={(event) =>
              setNewExpense({
                ...newExpense,
                accountId: event.target.value,
              })
            }
          >
            {accounts.map((account) => (
              <MenuItem key={account.id} value={account.id}>
                {account.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {editError && (
          <Typography variant="caption" color="error">
            {editError}
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="secondary"
          disabled={editLoading}
          onClick={() => handleEdit(expense.id, newExpense)}
        >
          {editLoading ? "Editing expense" : "Edit expense"}
        </Button>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

export default EditExpenseDialog;
