import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { BrowserRouter as Router } from "react-router-dom";
import AppRoutes from "./AppRoutes";
import Layout from "./Layout";
import { AuthProvider } from "./context/AuthContext";
import { UserThemeProvider } from "./context/UserThemeContext";

function App() {
  return (
    <AuthProvider>
      <UserThemeProvider>
        <Router>
          <Layout>
            <AppRoutes />
          </Layout>
        </Router>
      </UserThemeProvider>
    </AuthProvider>
  );
}

export default App;
