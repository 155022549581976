import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Slide,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { forwardRef, useState } from "react";
import { Account } from "../../hooks/accounts/useLoadAccounts";
import { Income } from "../../hooks/incomes/useLoadIncomes";

interface Props {
  income: Income;
  accounts: Account[];
  editLoading: boolean;
  editError: string | null;
  handleEdit: (
    id: string,
    patch: Pick<Income, "accountId" | "amount" | "interval" | "name"> & {
      dayOfMonth: number;
    }
  ) => void;
  onClose: () => void;
}

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

function EditIncomeDialog({
  income,
  editLoading,
  editError,
  accounts,
  handleEdit,
  onClose,
}: Props) {
  const theme = useTheme();

  const [newIncome, setNewIncome] = useState<
    Pick<Income, "accountId" | "amount" | "interval" | "name"> & {
      dayOfMonth: number;
    }
  >({
    ...income,
    dayOfMonth: new Date(income.date).getDate(),
  });

  return (
    <Dialog
      open={true}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      aria-describedby="alert-dialog-slide-description"
      maxWidth="sm"
      fullWidth
      sx={{ ".MuiPaper-root": { backgroundColor: theme.custom.dialogBg } }}
    >
      <DialogTitle>Edit income</DialogTitle>
      <DialogContent dividers>
        <FormControl fullWidth sx={{ mb: 4 }} variant="outlined">
          <TextField
            label="Income name"
            variant="outlined"
            fullWidth
            value={newIncome.name}
            onChange={(event) =>
              setNewIncome({ ...newIncome, name: event.target.value })
            }
          />
        </FormControl>
        <FormControl fullWidth sx={{ mb: 4 }}>
          <InputLabel>Amount</InputLabel>
          <OutlinedInput
            startAdornment={<InputAdornment position="start">£</InputAdornment>}
            label="Amount"
            fullWidth
            value={newIncome.amount}
            type="number"
            onChange={(event) =>
              setNewIncome({
                ...newIncome,
                amount: Number(event.target.value) ?? 0,
              })
            }
          />
        </FormControl>
        <FormControl fullWidth sx={{ mb: 4 }} variant="outlined">
          <TextField
            label="Interval"
            variant="outlined"
            disabled
            fullWidth
            value="monthly"
          />
        </FormControl>
        <FormControl fullWidth sx={{ mb: 4 }}>
          <InputLabel id="select-day-of-month-label">Day of month</InputLabel>
          <Select
            labelId="select-day-of-month-label"
            id="select-day-of-month"
            value={newIncome.dayOfMonth}
            label="Day of month"
            onChange={(event) =>
              setNewIncome({
                ...newIncome,
                dayOfMonth: Number(event.target.value) ?? 1,
              })
            }
          >
            {Array.from({ length: 28 }, (_, i) => i + 1).map((day) => (
              <MenuItem key={day} value={day}>
                {day}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel id="select-account-label">Account</InputLabel>
          <Select
            labelId="select-account-label"
            id="select-account"
            value={newIncome.accountId}
            label="Account"
            onChange={(event) =>
              setNewIncome({
                ...newIncome,
                accountId: event.target.value,
              })
            }
          >
            {accounts.map((account) => (
              <MenuItem key={account.id} value={account.id}>
                {account.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {editError && (
          <Typography variant="caption" color="error">
            {editError}
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="secondary"
          disabled={editLoading}
          onClick={() => handleEdit(income.id, newIncome)}
        >
          {editLoading ? "Editing account" : "Edit account"}
        </Button>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

export default EditIncomeDialog;
