import NotificationsIcon from "@mui/icons-material/Notifications";
import SaveIcon from "@mui/icons-material/Save";
import PaletteIcon from "@mui/icons-material/Palette";
import {
  AppBar,
  Badge,
  Button,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import { useCallback, useEffect, useState } from "react";
import UpdateAccountBalanceDialog from "../components/accounts/UpdateAccountBalanceDialog";
import UpdateAllAccountBalancesDialog from "../components/accounts/UpdateAllAccountBalancesDialog";
import AccountsBreakdownCard from "../components/dashboard/AccountsBreakdownCard";
import AccountsSummaryCard from "../components/dashboard/AccountsSummaryCard";
import AlertDialog from "../components/dashboard/AlertDialog";
import DailyBalanceCard from "../components/dashboard/DailyBalanceCard";
import ExpenseAtRiskCard from "../components/dashboard/ExpenseAtRiskCard";
import ExpensesCard from "../components/dashboard/ExpensesCard";
import ExpensesSummaryCard from "../components/dashboard/ExpensesSummaryCard";
import IncomesCard from "../components/dashboard/IncomesCard";
import IncomesSummaryCard from "../components/dashboard/IncomesSummaryCard";
import OverdueExpensesCard from "../components/dashboard/OverdueExpensesCard";
import TotalCard from "../components/dashboard/TotalCard";
import ZeroAccountBalanceCard from "../components/dashboard/ZeroAccountBalanceCard";
import ZeroTotalBalanceCard from "../components/dashboard/ZeroTotalBalanceCard";
import { drawerWidth } from "../components/layout/Drawer";
import { useAuthContext } from "../context/AuthContext";
import useAccountsClosingTotal from "../hooks/accounts/useAccountsClosingTotal";
import useAccountsTotal from "../hooks/accounts/useAccountsTotal";
import useLoadAccounts, { Account } from "../hooks/accounts/useLoadAccounts";
import useUpdateAccount from "../hooks/accounts/useUpdateAccount";
import useLoadBalancesTimeSeries from "../hooks/balances/useLoadBalancesTimeSeries";
import useCreatePaidExpense from "../hooks/expenses/useCreatePaidExpense";
import useLoadAlerts, { AlertType } from "../hooks/expenses/useLoadAlerts";
import useLoadExpensesSchedule, {
  ScheduledExpense,
} from "../hooks/expenses/useLoadExpensesSchedule";
import useLoadIncomesSchedule from "../hooks/incomes/useLoadIncomesSchedule";
import useLoadUserPreferences from "../hooks/user-preferences/userLoadUserPreferences";
import useUpdateUserPreferences from "../hooks/user-preferences/useUpdateUserPreferences";
import { useThemeContext } from "../context/UserThemeContext";
import CreateThemeDialog from "../components/themes/CreateThemeDialog";
import { Theme } from "../hooks/themes/useThemes";
import useCreateTheme from "../hooks/themes/useCreateTheme";
import EditThemeDialog from "../components/themes/EditThemeDialog";
import useUpdateTheme from "../hooks/themes/useUpdateTheme";

// TODO: refactor this page
export interface DateRange {
  from: string | null;
  to: string | null;
}

function DashboardPage() {
  const theme = useTheme();
  const { themes, reloadThemes } = useThemeContext();
  const isSmall = useMediaQuery("(max-width:600px)");

  const { user } = useAuthContext();
  const { data: userPreferencesData, refetch: refetchUserPreferences } =
    useLoadUserPreferences({
      userId: user?.uid || "",
    });

  const { mutation: updateUserPreferences } = useUpdateUserPreferences();

  const [dateRange, setDateRange] = useState<DateRange>({
    from: dayjs().startOf("month").toISOString(),
    to: dayjs().endOf("month").toISOString(),
  });
  const [chartIds, setChartIds] = useState<string[] | null>(null);
  const [alertDialog, setAlertDialog] = useState<{
    open: boolean;
    alertType: AlertType | null;
  }>({ open: false, alertType: null });
  const [openUpdateAllBalances, setOpenUpdateAllBalances] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState<Account | null>(null);
  const [openUpdateBalance, setOpenUpdateBalance] = useState(false);
  // theme menu
  const {
    mutation: createTheme,
    error: errorTheme,
    loading: loadingTheme,
  } = useCreateTheme();
  const {
    mutation: updateTheme,
    error: errorUpdateTheme,
    loading: loadingUpdateTheme,
  } = useUpdateTheme();
  const [openCreateTheme, setOpenCreateTheme] = useState(false);
  const [openEditTheme, setOpenEditTheme] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleThemeClick = (event: React.MouseEvent<SVGSVGElement>) => {
    setAnchorEl(event.currentTarget as unknown as HTMLElement);
  };
  const handleThemeMenuClick = async (themeId: string | null) => {
    if (!themeId) {
      setAnchorEl(null);
      return;
    }

    await updateUserPreferences({
      from: userPreferencesData?.from || "",
      to: userPreferencesData?.to || "",
      userId: user?.uid || "",
      themeId,
    });

    await reloadThemes();
    await refetchUserPreferences();

    setAnchorEl(null);
  };

  const handleCreateTheme = (themeConfig: Theme) => {
    createTheme(themeConfig);
    setOpenCreateTheme(false);
    reloadThemes();
  };

  const handleEditTheme = (themeConfig: Theme) => {
    updateTheme(themeConfig);
    setOpenEditTheme(false);
    reloadThemes();
  };
  //./theme menu

  const {
    data: accountsData,
    error,
    loading: accountsLoading,
    refetch: refetchAccounts,
  } = useLoadAccounts({ userId: user?.uid || "" });
  const { data: expenseScheduleData, refetch: refetchExpenseSchedule } =
    useLoadExpensesSchedule({ ...dateRange, userId: user?.uid || "" });
  const { data: incomeScheduleData } = useLoadIncomesSchedule({
    ...dateRange,
    userId: user?.uid || "",
  });
  const { mutation: createMutation } = useCreatePaidExpense();

  const {
    error: patchAccountError,
    loading: patchAccountLoading,
    mutation: patchAccount,
  } = useUpdateAccount();

  const accountsTotal = useAccountsTotal({ data: accountsData });

  const accountsClosingTotal = useAccountsClosingTotal({
    scheduledExpenses: expenseScheduleData,
    scheduledIncomes: incomeScheduleData,
    totalBalance: accountsTotal,
  });

  // todo, enable queries like this only once the current balance is loaded
  const { data: chartData, refetch: refetchTs } = useLoadBalancesTimeSeries({
    from: dateRange.from,
    to: dateRange.to,
    accountIds: chartIds,
    userId: user?.uid || "",
  });

  const {
    data: alertsData,
    error: alertsError,
    loading: alertsLoading,
    refetch: refetchAlerts,
  } = useLoadAlerts({
    from: dateRange.from,
    to: dateRange.to,
    userId: user?.uid || "",
  });

  const handleUpdateAccountBalances = () => {
    setOpenUpdateAllBalances(true);
  };

  const handleConfirmEditAccount = async (
    patchList: {
      accountId: string;
      patch: Partial<Account>;
    }[]
  ) => {
    await Promise.all(
      patchList.map(async ({ accountId, patch }) => {
        await patchAccount({ id: accountId, ...patch });
      })
    );
    await refetchExpenseSchedule();
    await refetchTs();
    await refetchAlerts();
    await refetchAccounts();
    setOpenUpdateAllBalances(false);
  };

  const handleOpenUpdateBalance = useCallback(
    (account: Account, _alertType: AlertType) => {
      setSelectedAccount(account);
      setOpenUpdateBalance(true);
    },
    []
  );

  const handleCloseAlertDialog = useCallback(() => {
    setAlertDialog({ alertType: null, open: false });
  }, []);

  const handleOpenAlertDialog = useCallback(
    (alertType: AlertType | null) => {
      setAlertDialog({ alertType, open: !alertDialog.open });
    },
    [alertDialog.open]
  );

  const handleMarkAsPaidForAlert = useCallback(
    async (expense: ScheduledExpense) => {
      // todo: some update all function
      // or break this up
      await createMutation({ ...expense, isPaid: true });
      await refetchExpenseSchedule();
      await refetchTs();
      await refetchAlerts();
      await refetchAccounts();
    },
    [
      createMutation,
      refetchAccounts,
      refetchAlerts,
      refetchExpenseSchedule,
      refetchTs,
    ]
  );

  const handleUpdateAccountBalance = useCallback(
    async (accountId: string, patch: Partial<Account>) => {
      await patchAccount({ id: accountId, ...patch });
      await refetchExpenseSchedule();
      await refetchTs();
      await refetchAlerts();
      await refetchAccounts();
      setSelectedAccount(null);
      setOpenUpdateBalance(false);
    },
    [
      patchAccount,
      refetchAccounts,
      refetchAlerts,
      refetchExpenseSchedule,
      refetchTs,
    ]
  );

  const handleDateRange = useCallback(
    (key: "from" | "to", value: Dayjs | null) => {
      setDateRange((prev) => ({
        ...prev,
        [key]: value?.toISOString() ?? null,
      }));
    },
    []
  );

  const handleSaveRange = useCallback(() => {
    if (!dateRange.from || !dateRange.to || !user?.uid) {
      return;
    }
    updateUserPreferences({
      userId: user?.uid || "",
      from: dateRange.from,
      to: dateRange.to,
    });
  }, [dateRange.from, dateRange.to, updateUserPreferences, user?.uid]);

  const handleToggleChart = useCallback(() => {
    const wasBreakdown = chartIds !== null;
    setChartIds(
      wasBreakdown ? null : accountsData.map((account) => account.id)
    );
  }, [chartIds, accountsData]);

  useEffect(() => {
    if (userPreferencesData) {
      setDateRange({
        from: dayjs(userPreferencesData.from).toISOString(),
        to: dayjs(userPreferencesData.to).toISOString(),
      });
    }
  }, [userPreferencesData]); // first render (not sure if this is true)

  if (accountsLoading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: theme.custom.main,
          color: theme.custom.border,
        }}
      >
        <Toolbar
          sx={{
            paddingTop: "20px",
            paddingBottom: "20px",
            marginLeft: isSmall ? undefined : `${drawerWidth}px`,
            marginRight: "10px",
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="From"
              onChange={(value) => handleDateRange("from", value)}
              value={dateRange.from ? dayjs(dateRange.from) : null}
              sx={{
                marginRight: "20px",
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: theme.custom.border,
                },
                ".MuiInputLabel-root": {
                  color: theme.custom.text,
                },
                ".MuiOutlinedInput-input": {
                  color: theme.custom.text,
                },
                ".MuiSvgIcon-root": {
                  color: theme.custom.text,
                },
              }}
            />
            <DatePicker
              label="To"
              onChange={(value) => handleDateRange("to", value)}
              value={dateRange.to ? dayjs(dateRange.to) : null}
              sx={{
                marginRight: isSmall ? undefined : "20px",
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: theme.custom.border,
                },
                ".MuiInputLabel-root": {
                  color: theme.custom.text,
                },
                ".MuiOutlinedInput-input": {
                  color: theme.custom.text,
                },
                ".MuiSvgIcon-root": {
                  color: theme.custom.text,
                },
              }}
            />
          </LocalizationProvider>
          {isSmall ? (
            <IconButton size="small" aria-label="delete">
              <SaveIcon sx={{ color: theme.custom.accent }} />
            </IconButton>
          ) : (
            <Button
              size="small"
              variant="contained"
              color="secondary"
              onClick={handleSaveRange}
            >
              Save range
            </Button>
          )}
          <div style={{ marginLeft: "auto", cursor: "pointer" }}>
            <PaletteIcon
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleThemeClick}
              sx={{
                color: theme.custom.accent,
                marginRight: "10px",
                cursor: "pointer",
              }}
            />
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={() => handleThemeMenuClick(null)}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              {themes.map((theme) => (
                <MenuItem
                  key={theme.id}
                  selected={theme.id === userPreferencesData?.themeId}
                  onClick={() => handleThemeMenuClick(theme.id)}
                >
                  {theme.label}
                </MenuItem>
              ))}
              <MenuItem
                key="custom"
                onClick={() => {
                  setOpenCreateTheme(true);
                  setAnchorEl(null);
                }}
              >
                Create new theme
              </MenuItem>
              <MenuItem
                key="edit"
                onClick={() => {
                  setOpenEditTheme(true);
                  setAnchorEl(null);
                }}
              >
                Edit theme
              </MenuItem>
            </Menu>
          </div>
          <Badge
            sx={{
              alignSelf: "center",
              color: theme.custom.accent,
              "&:hover": {
                cursor: alertsData.alertCount ? "pointer" : "default",
              },
            }}
            badgeContent={alertsData.alertCount}
            showZero
            color={alertsData.alertCount === 0 ? "success" : "error"}
            onClick={() => alertsData.alertCount && handleOpenAlertDialog(null)}
          >
            <NotificationsIcon sx={{ color: theme.custom.accent }} />
          </Badge>
        </Toolbar>
      </AppBar>
      <Grid container spacing={2} marginTop={"80px"} mb={2}>
        <Grid item xs={12} md={4}>
          <TotalCard
            accountsClosingTotal={accountsClosingTotal}
            accountsTotal={accountsTotal}
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <AccountsBreakdownCard
            accounts={accountsData}
            expenseScheduleData={expenseScheduleData}
            incomeScheduleData={incomeScheduleData}
            onUpdateBalances={handleUpdateAccountBalances}
          />
        </Grid>
      </Grid>
      {!isSmall && (
        <>
          <Grid container mb={2}>
            <Grid item xs={12}>
              <DailyBalanceCard
                chartData={chartData}
                onToggleBreakdown={handleToggleChart}
                isBreakdown={chartIds !== null}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} mb={2}>
            <Grid item xs={12} md={4}>
              <AccountsSummaryCard
                data={accountsData}
                label={`£${accountsTotal}`}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <IncomesSummaryCard data={incomeScheduleData} />
            </Grid>
            <Grid item xs={12} md={4}>
              <ExpensesSummaryCard data={expenseScheduleData} />
            </Grid>
          </Grid>
        </>
      )}
      <Grid container spacing={2} mb={2}>
        <Grid item xs={6} sm={6} md={3}>
          <OverdueExpensesCard
            data={alertsData.overdueExpense}
            onClick={() => handleOpenAlertDialog(AlertType.OverdueExpense)}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={3}>
          <ZeroTotalBalanceCard
            data={alertsData.zeroTotalBalance}
            onClick={() => handleOpenAlertDialog(AlertType.ZeroTotalBalance)}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={3}>
          <ZeroAccountBalanceCard
            data={alertsData.zeroAccountBalance}
            onClick={() => handleOpenAlertDialog(AlertType.ZeroAccountBalance)}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={3}>
          <ExpenseAtRiskCard
            data={alertsData.expenseAtRisk}
            onClick={() => handleOpenAlertDialog(AlertType.ExpenseAtRisk)}
          />
        </Grid>
      </Grid>
      <Grid container mb={2}>
        <Grid item xs={12}>
          <ExpensesCard
            data={expenseScheduleData}
            refetch={async () => {
              await refetchExpenseSchedule();
              await refetchTs();
              await refetchAlerts();
            }}
          />
        </Grid>
      </Grid>
      <Grid container mb={2}>
        <Grid item xs={12}>
          <IncomesCard data={incomeScheduleData} />
        </Grid>
      </Grid>
      {alertDialog.open && (
        <AlertDialog
          alertMap={alertsData}
          alertType={alertDialog.alertType}
          onClose={handleCloseAlertDialog}
          onUpdateBalance={handleOpenUpdateBalance}
          onMarkAsPaid={handleMarkAsPaidForAlert}
        />
      )}
      {openUpdateAllBalances && (
        <UpdateAllAccountBalancesDialog
          accountList={accountsData}
          editLoading={patchAccountLoading}
          editError={patchAccountError}
          handleEdit={handleConfirmEditAccount}
          onClose={() => {
            setOpenUpdateAllBalances(false);
          }}
        />
      )}
      {openUpdateBalance && selectedAccount && (
        <UpdateAccountBalanceDialog
          account={selectedAccount}
          editLoading={patchAccountLoading}
          editError={patchAccountError}
          handleEdit={handleUpdateAccountBalance}
          onClose={() => {
            setOpenUpdateBalance(false);
            setSelectedAccount(null);
          }}
        />
      )}
      {openCreateTheme && (
        <CreateThemeDialog
          loading={loadingTheme}
          error={errorTheme}
          userId={user?.uid || ""}
          onCreateTheme={handleCreateTheme}
          onClose={() => setOpenCreateTheme(false)}
        />
      )}
      {openEditTheme && (
        <EditThemeDialog
          loading={loadingUpdateTheme}
          error={errorUpdateTheme}
          userId={user?.uid || ""}
          onEditTheme={handleEditTheme}
          onClose={() => setOpenEditTheme(false)}
        />
      )}
      {/* 
        - hide db creds etc in env vars, using hosting
        - when creating payment dates, we need to use utc. when creating the expense and income schedules, we need to use utc too
        - clean up console warnings
        - toggle max on chart (to better see issues)
        - see income and expense on same transaction list (toggle)
        - highlight a dip to 0 on chart
        - consistent number formatting
        - filtering / sorting of expenses (be good to see monthly / one off / total by account) (on the dashboard page too?)
        - onboarding (blank account) experience
        - refactor
        - pause expenses
        - dismiss expenses
        - update expense from date (fork the expense?)
        - split payment (partial payment, payment contributions)
        - clone expense
        - add expense from dashboard
        - add many expenses
        - options for currency, conditional formatting of balances
        - credit card
        - savings (paying into accounts - type of payment? so you can have one off or scheduled contributions and ticket off as paid - you can update balances for now at least ) 
        - transpose dates if weekend
        - customise status thresholds
        - variable payments
        - update income and expenses from the list
        - bug: using alert ctas when the from date is 02/22/2024 (quite a while ago)
      */}
    </>
  );
}

export default DashboardPage;
