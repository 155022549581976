import { Alert } from "../../hooks/expenses/useLoadAlerts";
import AlertCard from "./AlertCard";

interface Props {
  data: Alert[];
  onClick: () => void;
}

function ZeroAccountBalanceCard({ data, onClick }: Props) {
  return (
    <AlertCard
      alertCount={data.length}
      label="Zero account balance"
      ctaLabel="View"
      onCTAClick={onClick}
    />
  );
}

export default ZeroAccountBalanceCard;
