import { useCallback, useEffect, useState } from "react";
import axiosInstance from "../../utils/axiosConfig";

export interface Income {
  id: string;
  accountId: string;
  accountName: string;
  amount: number;
  createdAt: string;
  date: string;
  interval: "monthly";
  name: string;
  userId: string;
}

const useLoadIncomes = ({ userId }: { userId: string }) => {
  const [data, setData] = useState<Income[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const fetchData = useCallback(async () => {
    try {
      const response = await axiosInstance.get<Income[]>("/api/incomes", {
        params: { userId },
      });
      setData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("Failed to fetch data.");
    } finally {
      setLoading(false);
    }
  }, [userId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return { data, error, loading, refetch: fetchData };
};

export default useLoadIncomes;
