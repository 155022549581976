import { Navigate, Route, Routes } from "react-router-dom";
import AccountsPage from "./pages/AccountsPage";
import DashboardPage from "./pages/DashboardPage";
import ExpensesPage from "./pages/ExpensesPage";
import HomePage from "./pages/HomePage";
import IncomesPage from "./pages/IncomesPage";
import { useAuthContext } from "./context/AuthContext";
import NotFoundPage from "./pages/NotFoundPage";

function AppRoutes() {
  const { user } = useAuthContext();

  return (
    <Routes>
      {!user ? (
        <Route path="/" element={<HomePage />} />
      ) : (
        <>
          <Route path="/" element={<DashboardPage />} />
          <Route path="/accounts" element={<AccountsPage />} />
          <Route path="/incomes" element={<IncomesPage />} />
          <Route path="/expenses" element={<ExpensesPage />} />
        </>
      )}
      <Route path="/404" element={<NotFoundPage />} />
      <Route path="*" element={<Navigate to="/404" replace />} />
    </Routes>
  );
}

export default AppRoutes;
