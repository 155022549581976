import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import PriceChangeIcon from "@mui/icons-material/PriceChange";
import {
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import AddAccountDialog from "../components/accounts/AddAccountDialog";
import DeleteAccountDialog from "../components/accounts/DeleteAccountDialog";
import EditAccountDialog from "../components/accounts/EditAccountDialog";
import UpdateAccountBalanceDialog from "../components/accounts/UpdateAccountBalanceDialog";
import { useAuthContext } from "../context/AuthContext";
import useCreateAccount from "../hooks/accounts/useCreateAccount";
import useDeleteAccount from "../hooks/accounts/useDeleteAccount";
import useLoadAccounts, { Account } from "../hooks/accounts/useLoadAccounts";
import useUpdateAccount from "../hooks/accounts/useUpdateAccount";

function AccountsPage() {
  const theme = useTheme();
  const { user } = useAuthContext();
  const [open, setOpen] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState<Account | null>(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openUpdateBalance, setOpenUpdateBalance] = useState(false);
  const { data, error, loading, refetch } = useLoadAccounts({
    userId: user?.uid || "",
  });

  const { loading: deletingAccount, mutation: deleteAccount } =
    useDeleteAccount();

  const {
    error: postError,
    loading: postLoading,
    mutation: createAccount,
  } = useCreateAccount();

  const {
    loading: editingAccount,
    error: editError,
    mutation: updateAccount,
  } = useUpdateAccount();

  const handlePost = async (account: Pick<Account, "amount" | "name">) => {
    if (user?.uid) {
      await createAccount({ userId: user.uid, ...account });
    }
    setOpen(false);
    await refetch();
  };

  const handleConfirmDeleteAccount = async (account: Account) => {
    setSelectedAccount(account);
    setOpenDelete(true);
  };

  const handleEditAccount = (account: Account) => {
    setSelectedAccount(account);
    setOpenEdit(true);
  };

  const handleUpdateAccountBalance = (account: Account) => {
    setSelectedAccount(account);
    setOpenUpdateBalance(true);
  };

  const handleDeleteAccount = async (accountId: string) => {
    await deleteAccount(accountId);
    setOpenDelete(false);
    await refetch();
  };

  const handleConfirmEditAccount = async (
    accountId: string,
    patch: Partial<Account>
  ) => {
    await updateAccount({ id: accountId, ...patch });
    setOpenEdit(false);
    setOpenUpdateBalance(false);
    await refetch();
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  // TODO:
  // actions
  // search
  // filter
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} display="flex">
          <Button
            sx={{ marginLeft: "auto" }}
            variant="contained"
            color="secondary"
            onClick={() => setOpen(true)}
          >
            Add account
          </Button>
        </Grid>
        {/* table of accounts */}
        <Grid item xs={12}>
          <Card
            variant="outlined"
            sx={{
              backgroundColor: theme.custom.main,
              borderColor: theme.custom.border,
              height: "100%",
            }}
          >
            <CardContent>
              <Typography
                gutterBottom
                sx={{
                  color: theme.custom.text,
                  fontSize: 14,
                }}
              >
                Accounts
              </Typography>
              <TableContainer
                sx={{
                  backgroundColor: theme.custom.main,
                  boxShadow: "none",
                  color: theme.custom.text,
                }}
                component={Paper}
              >
                <Table
                  sx={{ color: theme.custom.text }}
                  aria-label="simple table"
                >
                  <TableHead sx={{ color: theme.custom.text }}>
                    <TableRow>
                      <TableCell sx={{ color: theme.custom.text }}>
                        Name
                      </TableCell>
                      <TableCell
                        sx={{ color: theme.custom.text }}
                        align="right"
                      >
                        Balance
                      </TableCell>
                      <TableCell
                        sx={{ color: theme.custom.text }}
                        width={"15%"}
                        align="right"
                      >
                        Actions
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.map((row) => (
                      <TableRow
                        key={row.name}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          sx={{ color: theme.custom.text }}
                          component="th"
                          scope="row"
                        >
                          {row.name}
                        </TableCell>
                        <TableCell
                          sx={{ color: theme.custom.text }}
                          align="right"
                        >
                          £{row.amount}
                        </TableCell>
                        <TableCell
                          sx={{ color: theme.custom.text }}
                          align="right"
                        >
                          {/* edit, delete, update balance */}
                          <Tooltip title="Edit">
                            <IconButton
                              aria-label="edit"
                              onClick={() => handleEditAccount(row)}
                              size="small"
                              color="secondary"
                            >
                              <EditIcon fontSize="inherit" />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Update balance">
                            <IconButton
                              aria-label="update balance"
                              onClick={() => handleUpdateAccountBalance(row)}
                              size="small"
                              color="secondary"
                            >
                              <PriceChangeIcon fontSize="inherit" />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Delete">
                            <IconButton
                              aria-label="delete"
                              onClick={() => handleConfirmDeleteAccount(row)}
                              size="small"
                              color="secondary"
                            >
                              <DeleteForeverIcon fontSize="inherit" />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {open && (
        <AddAccountDialog
          onClose={() => setOpen(false)}
          postLoading={postLoading}
          postError={postError}
          handlePost={handlePost}
        />
      )}
      {openDelete && selectedAccount && (
        <DeleteAccountDialog
          account={selectedAccount}
          deleteLoading={deletingAccount}
          handleDelete={handleDeleteAccount}
          onClose={() => {
            setOpenDelete(false);
            setSelectedAccount(null);
          }}
        />
      )}
      {openEdit && selectedAccount && (
        <EditAccountDialog
          account={selectedAccount}
          editLoading={editingAccount}
          editError={editError}
          handleEdit={handleConfirmEditAccount}
          onClose={() => {
            setOpenEdit(false);
            setSelectedAccount(null);
          }}
        />
      )}
      {openUpdateBalance && selectedAccount && (
        <UpdateAccountBalanceDialog
          account={selectedAccount}
          editLoading={editingAccount}
          editError={editError}
          handleEdit={handleConfirmEditAccount}
          onClose={() => {
            setOpenUpdateBalance(false);
            setSelectedAccount(null);
          }}
        />
      )}
    </>
  );
}

export default AccountsPage;
