import { useCallback, useState } from "react";
import axiosInstance from "../../utils/axiosConfig";
import { Account } from "./useLoadAccounts";

const useCreateAccount = () => {
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const mutation = useCallback(async (account: Omit<Account, "id">) => {
    try {
      setLoading(true);
      await axiosInstance.post<Account[]>("/api/accounts", account);
    } catch (error) {
      console.error("Error posting data:", error);
      setError("Failed to post data.");
    } finally {
      setLoading(false);
    }
  }, []);

  return { error, loading, mutation };
};

export default useCreateAccount;
