import { createContext, useContext, useEffect, useState } from "react";
import Cookies from "js-cookie";

interface User {
  email: string | null;
  uid: string | null;
}

const AuthContext = createContext<{
  user: User | null;
  setUser: (user: User | null) => void;
}>({
  user: null,
  setUser: (user: User | null) => {},
});

function getUser(): User | null {
  const user = Cookies.get("user");
  return user ? JSON.parse(user) : null;
}

export const AuthProvider = ({ children }: { children: JSX.Element }) => {
  const [user, setUser] = useState<User | null>(getUser());

  const handleSetUser = (user: User | null) => {
    setUser(user);
    if (!user) {
      Cookies.remove("user");
    } else {
      Cookies.set("user", JSON.stringify(user), { expires: 7 }); // Store user data for 7 days
    }
  };

  useEffect(() => {
    const userData = Cookies.get("user");
    if (userData) {
      setUser(JSON.parse(userData));
    }
  }, []);

  return (
    <AuthContext.Provider value={{ user, setUser: handleSetUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => useContext(AuthContext);
