import { useCallback, useState } from "react";
import axiosInstance from "../../utils/axiosConfig";

const useDeletePaidExpense = () => {
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const mutation = useCallback(async (paymentId: string) => {
    try {
      setLoading(true);
      await axiosInstance.delete(`/api/paid-expense?paymentId=${paymentId}`);
    } catch (error) {
      console.error("Error deleting data:", error);
      setError("Failed to delete data.");
    } finally {
      setLoading(false);
    }
  }, []);

  return { error, loading, mutation };
};

export default useDeletePaidExpense;
