import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  useTheme,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { forwardRef } from "react";
import { Income } from "../../hooks/incomes/useLoadIncomes";

interface Props {
  income: Income;
  deleteLoading: boolean;
  handleDelete: (id: string) => void;
  onClose: () => void;
}

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

function DeleteIncomeDialog({
  income,
  deleteLoading,
  handleDelete,
  onClose,
}: Props) {
  const theme = useTheme();

  return (
    <Dialog
      open={true}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      aria-describedby="alert-dialog-slide-description"
      maxWidth="sm"
      fullWidth
      sx={{ ".MuiPaper-root": { backgroundColor: theme.custom.dialogBg } }}
    >
      <DialogTitle>Delete income</DialogTitle>
      <DialogContent dividers>
        Are you sure you want to delete the income: {income.name}?
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="secondary"
          disabled={deleteLoading}
          onClick={() => handleDelete(income.id)}
        >
          {deleteLoading ? "Deleting income" : "Delete income"}
        </Button>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

export default DeleteIncomeDialog;
