import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import {
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import dayjs from "dayjs";
import { useState } from "react";
import AddExpenseDialog from "../components/expenses/AddExpenseDialog";
import DeleteExpenseDialog from "../components/expenses/DeleteExpenseDialog";
import EditExpenseDialog from "../components/expenses/EditExpenseDialog";
import { useAuthContext } from "../context/AuthContext";
import useLoadAccounts from "../hooks/accounts/useLoadAccounts";
import useCreateExpense from "../hooks/expenses/useCreateExpense";
import useDeleteExpense from "../hooks/expenses/useDeleteExpense";
import useLoadExpenses, { Expense } from "../hooks/expenses/useLoadExpenses";
import useUpdateExpense from "../hooks/expenses/useUpdateExpense";

function ExpensesPage() {
  const theme = useTheme();
  const { user } = useAuthContext();

  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [selectedExpense, setSelectedExpense] = useState<Expense | null>(null);

  const { data, error, loading, refetch } = useLoadExpenses({
    userId: user?.uid || "",
  });

  const { data: accountsData } = useLoadAccounts({ userId: user?.uid || "" });

  const { loading: deletingExpense, mutation: deleteExpense } =
    useDeleteExpense();
  const {
    loading: updatingExpense,
    error: editError,
    mutation: updateExpense,
  } = useUpdateExpense();

  const {
    error: postError,
    loading: postLoading,
    mutation,
  } = useCreateExpense();

  const handlePost = async (
    expense: Pick<Expense, "accountId" | "amount" | "interval" | "name"> & {
      dayOfWeek: number | null;
      dayOfMonth: number | null;
      date: string | null;
    }
  ) => {
    if (user?.uid) {
      if (expense.interval === "weekly" && expense.dayOfWeek !== null) {
        await mutation({
          userId: user.uid,
          ...expense,
          date: dayjs().day(expense.dayOfWeek).toISOString(),
        });
      }

      if (expense.interval === "monthly" && expense.dayOfMonth) {
        const currentDate = new Date();
        currentDate.setDate(expense.dayOfMonth);

        await mutation({
          userId: user.uid,
          ...expense,
          date: currentDate.toISOString(),
        });
      }

      if (expense.interval === "yearly" && expense.date) {
        await mutation({
          userId: user.uid,
          ...expense,
          date: expense.date,
        });
      }

      if (expense.interval === "once" && expense.date) {
        await mutation({
          userId: user.uid,
          ...expense,
          date: expense.date,
        });
      }
    }

    setOpen(false);
    await refetch();
  };

  const handleConfirmDeleteExpense = async (expense: Expense) => {
    setSelectedExpense(expense);
    setOpenDelete(true);
  };

  const handleDeleteExpense = async (expenseId: string) => {
    await deleteExpense(expenseId);
    setOpenDelete(false);
    await refetch();
  };

  const handleEditExpense = (expense: Expense) => {
    setSelectedExpense(expense);
    setOpenEdit(true);
  };

  const handleConfirmEditExpense = async (
    id: string,
    patch: Pick<Expense, "accountId" | "amount" | "interval" | "name"> & {
      dayOfWeek: number | null;
      dayOfMonth: number | null;
      date: string | null;
    }
  ) => {
    if (user?.uid) {
      if (patch.interval === "weekly" && patch.dayOfWeek !== null) {
        await updateExpense({
          userId: user.uid,
          id,
          ...patch,
          date: dayjs().day(patch.dayOfWeek).toISOString(),
        });
      }

      if (patch.interval === "monthly" && patch.dayOfMonth) {
        const currentDate = new Date();
        currentDate.setDate(patch.dayOfMonth);

        await updateExpense({
          userId: user.uid,
          id,
          ...patch,
          date: currentDate.toISOString(), // last to ensure override
        });
      }

      if (patch.interval === "yearly" && patch.date) {
        await updateExpense({
          userId: user.uid,
          id,
          ...patch,
          date: patch.date,
        });
      }

      if (patch.interval === "once" && patch.date) {
        await updateExpense({
          userId: user.uid,
          id,
          ...patch,
          date: patch.date,
        });
      }
    }

    setOpenEdit(false);
    await refetch();
  };

  const formatDate = (row: Expense) => {
    if (row.interval === "once") {
      return dayjs(row.date).format("Do MMMM YYYY");
    }

    if (row.interval === "yearly") {
      return dayjs(row.date).format("Do MMMM");
    }

    if (row.interval === "monthly") {
      return dayjs(row.date).format("Do");
    }

    if (row.interval === "weekly") {
      return dayjs(row.date).format("dddd");
    }

    return "Unknown";
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (postLoading) {
    return <div>Posting...</div>;
  }

  // todo:
  // tagging of expenses
  return (
    // <Expenses expenses={data} />
    //<CreateExpense handlePost={handlePost} postLoading={postLoading} />
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} display="flex">
          <Button
            sx={{ marginLeft: "auto" }}
            variant="contained"
            color="secondary"
            onClick={() => setOpen(true)}
          >
            Add expense
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Card
            variant="outlined"
            sx={{
              backgroundColor: theme.custom.main,
              borderColor: theme.custom.border,
              height: "100%",
            }}
          >
            <CardContent>
              <Typography
                gutterBottom
                sx={{
                  color: theme.custom.text,
                  fontSize: 14,
                }}
              >
                Expenses
              </Typography>
              <TableContainer
                sx={{
                  backgroundColor: theme.custom.main,
                  boxShadow: "none",
                  color: theme.custom.text,
                }}
                component={Paper}
              >
                <Table
                  sx={{ color: theme.custom.text }}
                  aria-label="simple table"
                >
                  <TableHead sx={{ color: theme.custom.text }}>
                    <TableRow>
                      <TableCell sx={{ color: theme.custom.text }}>
                        Name
                      </TableCell>
                      <TableCell sx={{ color: theme.custom.text }}>
                        Interval
                      </TableCell>
                      <TableCell sx={{ color: theme.custom.text }}>
                        Date
                      </TableCell>
                      <TableCell sx={{ color: theme.custom.text }}>
                        Account
                      </TableCell>
                      <TableCell
                        sx={{ color: theme.custom.text }}
                        align="right"
                      >
                        Amount
                      </TableCell>
                      <TableCell
                        sx={{ color: theme.custom.text }}
                        width={"15%"}
                        align="right"
                      >
                        Actions
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.map((row) => (
                      <TableRow
                        key={row.name}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          sx={{ color: theme.custom.text }}
                          component="th"
                          scope="row"
                        >
                          {row.name}
                        </TableCell>
                        <TableCell sx={{ color: theme.custom.text }}>
                          {row.interval}
                        </TableCell>
                        <TableCell sx={{ color: theme.custom.text }}>
                          {formatDate(row)}
                        </TableCell>
                        <TableCell sx={{ color: theme.custom.text }}>
                          {row.accountName}
                        </TableCell>
                        <TableCell
                          sx={{ color: theme.custom.text }}
                          align="right"
                        >
                          £{row.amount}
                        </TableCell>
                        <TableCell
                          sx={{ color: theme.custom.text }}
                          align="right"
                        >
                          <Tooltip title="Edit">
                            <IconButton
                              aria-label="edit"
                              size="small"
                              color="secondary"
                              onClick={() => handleEditExpense(row)}
                            >
                              <EditIcon fontSize="inherit" />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Delete">
                            <IconButton
                              aria-label="delete"
                              size="small"
                              color="secondary"
                              onClick={() => handleConfirmDeleteExpense(row)}
                            >
                              <DeleteForeverIcon fontSize="inherit" />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {open && (
        <AddExpenseDialog
          accounts={accountsData}
          onClose={() => setOpen(false)}
          postLoading={postLoading}
          postError={postError}
          handlePost={handlePost}
        />
      )}
      {openDelete && selectedExpense && (
        <DeleteExpenseDialog
          expense={selectedExpense}
          deleteLoading={deletingExpense}
          handleDelete={handleDeleteExpense}
          onClose={() => {
            setOpenDelete(false);
            setSelectedExpense(null);
          }}
        />
      )}
      {openEdit && selectedExpense && (
        <EditExpenseDialog
          expense={selectedExpense}
          accounts={accountsData}
          editLoading={updatingExpense}
          editError={editError}
          handleEdit={handleConfirmEditExpense}
          onClose={() => {
            setOpenEdit(false);
            setSelectedExpense(null);
          }}
        />
      )}
    </>
  );
}

export default ExpensesPage;
