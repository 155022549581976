import { Button, Grid, TextField, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../context/AuthContext";
import useSignIn from "../../hooks/authentication/useSignIn";

function SignInForm() {
  const theme = useTheme();
  const { setUser } = useAuthContext();
  const navigate = useNavigate();

  const [newCredentials, setNewCredentials] = useState<{
    email: string | null;
    password: string | null;
  }>({
    email: null,
    password: null,
  });

  const {
    data: postData,
    error: postError,
    loading: postLoading,
    mutation: signIn,
  } = useSignIn();

  const handleSignIn = async () => {
    await signIn({
      email: newCredentials.email!,
      password: newCredentials.password!,
    });
  };

  useEffect(() => {
    const userData = postData?.data?.uid
      ? { uid: postData.data.uid, email: postData.data.email }
      : null;

    setUser(userData);
    if (postData) {
      navigate("/");
    }
  }, [postData, setUser, navigate]);
  console.log("env:", process.env);

  return (
    <>
      <Grid container spacing={2} padding="15%">
        <Grid item xs={12} mb={3}>
          <TextField
            autoFocus
            sx={{ background: theme.custom.accent }}
            label="email"
            variant="filled"
            color="primary"
            fullWidth
            value={newCredentials.email}
            onChange={(event) =>
              setNewCredentials({
                ...newCredentials,
                email: event.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={12} mb={3}>
          <TextField
            autoFocus
            sx={{ background: theme.custom.accent }}
            label="password"
            type="password"
            variant="filled"
            color="primary"
            fullWidth
            value={newCredentials.password}
            onChange={(event) =>
              setNewCredentials({
                ...newCredentials,
                password: event.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            sx={{ marginLeft: "auto", color: theme.custom.main }}
            variant="contained"
            color="secondary"
            disabled={!newCredentials.email || !newCredentials.password}
            onClick={handleSignIn}
          >
            Sign in
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

export default SignInForm;
