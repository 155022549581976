import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Slide,
  Typography,
  useTheme,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { forwardRef, useMemo, useState } from "react";
import { defaultTheme, useThemeContext } from "../../context/UserThemeContext";
import useThemes, { Theme } from "../../hooks/themes/useThemes";
import useDeleteTheme from "../../hooks/themes/useDeleteTheme";

interface Props {
  error: string | null;
  loading: boolean;
  userId: string;
  onEditTheme: (themeConfig: Theme) => void;
  onClose: () => void;
}

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

function EditThemeDialog({
  error,
  loading,
  userId,
  onEditTheme,
  onClose,
}: Props) {
  const theme = useTheme();
  const { reloadThemes } = useThemeContext();

  const { data, refetch } = useThemes();

  const { mutation: deleteTheme, loading: loadingDelete } = useDeleteTheme();

  const [selectedTheme, setSelectedTheme] = useState<Theme | null>(null);
  const [deleteThemeWarning, setDeleteThemeWarning] = useState<boolean>(false);

  const userThemes = useMemo(
    () => data.filter((d) => d.owner === userId),
    [data, userId]
  );

  return (
    <>
      <Dialog
        open={true}
        TransitionComponent={Transition}
        keepMounted
        onClose={onClose}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="sm"
        fullWidth
        sx={{ ".MuiPaper-root": { backgroundColor: theme.custom.dialogBg } }}
      >
        <DialogTitle>Edit theme</DialogTitle>
        <DialogContent dividers>
          <FormControl fullWidth sx={{ mb: 4 }}>
            <InputLabel id="select-interval">Select theme</InputLabel>
            <Select
              labelId="select-theme-label"
              id="select-theme"
              value={selectedTheme?.id || ""}
              label="Select theme"
              onChange={(event) =>
                setSelectedTheme(
                  userThemes.find((t) => t.id === event.target.value) || null
                )
              }
            >
              {userThemes.map((theme) => (
                <MenuItem key={theme.id} value={theme.id}>
                  {theme.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {selectedTheme && (
            <>
              <FormControl fullWidth sx={{ mb: 4 }}>
                <InputLabel>Accent colour</InputLabel>
                <OutlinedInput
                  label="Accent colour"
                  type="color"
                  fullWidth
                  value={selectedTheme.accent}
                  onChange={(event) =>
                    setSelectedTheme({
                      ...selectedTheme,
                      accent: event.target.value,
                    })
                  }
                />
              </FormControl>
              <FormControl fullWidth sx={{ mb: 4 }}>
                <InputLabel>Main colour</InputLabel>
                <OutlinedInput
                  label="Main colour"
                  type="color"
                  fullWidth
                  value={selectedTheme.main}
                  onChange={(event) =>
                    setSelectedTheme({
                      ...selectedTheme,
                      main: event.target.value,
                    })
                  }
                />
              </FormControl>
              <FormControl fullWidth sx={{ mb: 4 }}>
                <InputLabel>Text colour</InputLabel>
                <OutlinedInput
                  label="Text colour"
                  type="color"
                  fullWidth
                  value={selectedTheme.text}
                  onChange={(event) =>
                    setSelectedTheme({
                      ...selectedTheme,
                      text: event.target.value,
                    })
                  }
                />
              </FormControl>
              <FormControl fullWidth sx={{ mb: 4 }}>
                <InputLabel>Background colour</InputLabel>
                <OutlinedInput
                  label="Background colour"
                  type="color"
                  fullWidth
                  value={selectedTheme.bg}
                  onChange={(event) =>
                    setSelectedTheme({
                      ...selectedTheme,
                      bg: event.target.value,
                    })
                  }
                />
              </FormControl>
              <FormControl fullWidth sx={{ mb: 4 }}>
                <InputLabel>Border colour</InputLabel>
                <OutlinedInput
                  label="Border colour"
                  type="color"
                  fullWidth
                  value={selectedTheme.border}
                  onChange={(event) =>
                    setSelectedTheme({
                      ...selectedTheme,
                      border: event.target.value,
                    })
                  }
                />
              </FormControl>
              <FormControl fullWidth sx={{ mb: 4 }}>
                <InputLabel>Dialog background colour</InputLabel>
                <OutlinedInput
                  label="Dialog background colour"
                  type="color"
                  fullWidth
                  value={selectedTheme.dialogBg}
                  onChange={(event) =>
                    setSelectedTheme({
                      ...selectedTheme,
                      dialogBg: event.target.value,
                    })
                  }
                />
              </FormControl>
              <FormControl fullWidth sx={{ mb: 4 }}>
                <InputLabel>Theme name</InputLabel>
                <OutlinedInput
                  label="Theme name"
                  type="text"
                  fullWidth
                  value={selectedTheme.label}
                  onChange={(event) =>
                    setSelectedTheme({
                      ...selectedTheme,
                      label: event.target.value,
                    })
                  }
                />
              </FormControl>
            </>
          )}
          {error && (
            <Typography variant="caption" color="error">
              {error}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="secondary"
            disabled={loading || !selectedTheme}
            onClick={() => onEditTheme(selectedTheme!)}
          >
            {loading ? "Editing theme" : "Edit theme"}
          </Button>
          <Button onClick={onClose}>Close</Button>
          <Button
            disabled={!selectedTheme}
            onClick={() => setDeleteThemeWarning(true)}
          >
            Delete theme
          </Button>
        </DialogActions>
      </Dialog>

      {deleteThemeWarning && (
        <Dialog
          open={true}
          TransitionComponent={Transition}
          keepMounted
          onClose={onClose}
          aria-describedby="alert-dialog-slide-description"
          maxWidth="sm"
          fullWidth
          sx={{ ".MuiPaper-root": { backgroundColor: theme.custom.dialogBg } }}
        >
          <DialogTitle>Delete income</DialogTitle>
          <DialogContent dividers>
            Are you sure you want to delete the theme: {selectedTheme?.label}?
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="secondary"
              disabled={loadingDelete}
              onClick={() => {
                deleteTheme(selectedTheme!.id);
                setDeleteThemeWarning(false);
                setSelectedTheme(null);
                refetch();
                reloadThemes();
              }}
            >
              {loadingDelete ? "Deleting theme" : "Delete theme"}
            </Button>
            <Button onClick={() => setDeleteThemeWarning(false)}>Close</Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}

export default EditThemeDialog;
