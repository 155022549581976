import { useMemo } from "react";
import { ScheduledExpense } from "../expenses/useLoadExpensesSchedule";
import { Income } from "../incomes/useLoadIncomes";

interface Props {
  totalBalance: number;
  scheduledExpenses: ScheduledExpense[];
  scheduledIncomes: Income[];
}

function useAccountsClosingTotal({
  totalBalance,
  scheduledExpenses,
  scheduledIncomes,
}: Props) {
  const outstandingExpenseTotal = useMemo(() => {
    return scheduledExpenses.reduce(
      (acc, { amount, isPaid }) => acc + (isPaid ? 0 : amount || 0),
      0
    );
  }, [scheduledExpenses]);

  const totalIncome = useMemo(() => {
    return scheduledIncomes.reduce((acc, { amount }) => acc + amount || 0, 0);
  }, [scheduledIncomes]);

  return parseFloat(
    (totalBalance - outstandingExpenseTotal + totalIncome).toFixed(2)
  );
}

export default useAccountsClosingTotal;
