import NotificationImportantIcon from "@mui/icons-material/NotificationImportant";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
  useTheme,
} from "@mui/material";
import { statusColorMap } from "../../theme";

interface Props {
  alertCount: number;
  label: string;
  ctaLabel: string;
  onCTAClick: () => void;
}

function AlertCard({ alertCount, label, ctaLabel, onCTAClick }: Props) {
  const theme = useTheme();

  return (
    <Card
      variant="outlined"
      sx={{
        backgroundColor: alertCount ? statusColorMap.danger : theme.custom.main,
        borderColor: theme.custom.border,
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <>
        <CardContent sx={{ flexGrow: 1 }}>
          <>
            {/* could make an alert card */}
            <Typography
              gutterBottom
              sx={{
                color: theme.custom.text,
                fontSize: 14,
              }}
              display="flex"
              alignItems="center"
            >
              {alertCount > 0 && (
                <NotificationImportantIcon sx={{ marginRight: "5px" }} />
              )}
              {label}
            </Typography>

            <Typography
              align="center"
              sx={{
                color: theme.custom.text,
                fontSize: 60,
                mt: 8,
              }}
            >
              {alertCount}
            </Typography>
          </>
        </CardContent>
        <CardActions sx={{ flexGrow: 1 }}>
          {alertCount > 0 && (
            <Button
              sx={{ marginLeft: "auto", mr: 1, mt: 4 }}
              size="small"
              variant="contained"
              color="secondary"
              onClick={onCTAClick}
            >
              {ctaLabel}
            </Button>
          )}
        </CardActions>
      </>
    </Card>
  );
}

export default AlertCard;
