import BloodtypeIcon from "@mui/icons-material/Bloodtype";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import InsightsIcon from "@mui/icons-material/Insights";
import LogoutIcon from "@mui/icons-material/Logout";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import WalletIcon from "@mui/icons-material/Wallet";
import { Theme, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import MUIDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import { NavLink, NavLinkRenderProps, useNavigate } from "react-router-dom";
import { useAuthContext } from "../../context/AuthContext";
import useSignOut from "../../hooks/authentication/useSignOut";

export const drawerWidth = 240;

export const navLinkStyle = ({
  isActive,
  theme,
}: NavLinkRenderProps & { theme: Theme }) => ({
  textDecoration: "none",
  display: "flex",
  color: "initial",
  borderRadius: "5px",
  ...(isActive && {
    backgroundColor: theme.custom.accent,
  }),
});

function Drawer() {
  const theme = useTheme();
  const { mutation: signOut } = useSignOut();
  const navigate = useNavigate();

  const { setUser } = useAuthContext();

  const handleSignOut = async () => {
    await signOut();
    setUser(null);
    navigate("/");
  };

  return (
    <MUIDrawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: drawerWidth,
          boxSizing: "border-box",
        },
      }}
    >
      <Box sx={{ overflow: "auto", marginTop: "5px", height: "100%" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <List>
              <ListItem
                key="accountDracula"
                disablePadding
                sx={{ marginBottom: 3, color: "white" }}
              >
                <BloodtypeIcon sx={{ marginRight: 1 }} />
                <Typography
                  variant="h6"
                  noWrap
                  component="div"
                  sx={{
                    height: "64px",
                    width: "192px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Account Dracula
                </Typography>
              </ListItem>
              <NavLink
                to="/"
                style={(props) => navLinkStyle({ ...props, theme })}
              >
                <ListItem key="dashboard" disablePadding>
                  <ListItemButton>
                    <ListItemIcon>
                      <InsightsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Dashboard" />
                  </ListItemButton>
                </ListItem>
              </NavLink>
            </List>
            <Divider />
            <List>
              <NavLink
                to="/accounts"
                style={(props) => navLinkStyle({ ...props, theme })}
              >
                <ListItem key="accounts" disablePadding>
                  <ListItemButton>
                    <ListItemIcon>
                      <CreditCardIcon />
                    </ListItemIcon>
                    <ListItemText primary="Accounts" />
                  </ListItemButton>
                </ListItem>
              </NavLink>
              <NavLink
                to="/incomes"
                style={(props) => navLinkStyle({ ...props, theme })}
              >
                <ListItem key="income" disablePadding>
                  <ListItemButton>
                    <ListItemIcon>
                      <WalletIcon />
                    </ListItemIcon>
                    <ListItemText primary="Income" />
                  </ListItemButton>
                </ListItem>
              </NavLink>
              <NavLink
                to="/expenses"
                style={(props) => navLinkStyle({ ...props, theme })}
              >
                <ListItem key="expenses" disablePadding>
                  <ListItemButton>
                    <ListItemIcon>
                      <ShoppingCartCheckoutIcon />
                    </ListItemIcon>
                    <ListItemText primary="Expenses" />
                  </ListItemButton>
                </ListItem>
              </NavLink>
            </List>
          </Box>
          <List>
            <ListItem key="user" disablePadding>
              <ListItemButton onClick={handleSignOut}>
                <ListItemIcon>
                  <LogoutIcon />
                </ListItemIcon>
                <ListItemText primary="Sign out" />
              </ListItemButton>
            </ListItem>
          </List>
        </Box>
      </Box>
    </MUIDrawer>
  );
}

export default Drawer;
