import { useCallback, useState } from "react";
import axiosInstance from "../../utils/axiosConfig";
import { Expense } from "./useLoadExpenses";

const useUpdateExpense = () => {
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const mutation = useCallback(async (expense: Partial<Expense>) => {
    try {
      setLoading(true);
      await axiosInstance.patch<Expense[]>("/api/expense", expense);
    } catch (error) {
      console.error("Error patching data:", error);
      setError("Failed to patch data.");
    } finally {
      setLoading(false);
    }
  }, []);

  return { error, loading, mutation };
};

export default useUpdateExpense;
