import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
  useTheme,
} from "@mui/material";
import { Account } from "../../hooks/accounts/useLoadAccounts";
import { ScheduledExpense } from "../../hooks/expenses/useLoadExpensesSchedule";
import { Income } from "../../hooks/incomes/useLoadIncomes";
import AccountsBreakdown from "./AccountsBreakdown";

interface Props {
  accounts: Account[];
  expenseScheduleData: ScheduledExpense[];
  incomeScheduleData: Income[];
  onUpdateBalances: () => void;
}

function AccountsBreakdownCard({
  accounts,
  expenseScheduleData,
  incomeScheduleData,
  onUpdateBalances,
}: Props) {
  const theme = useTheme();

  return (
    <Card
      variant="outlined"
      sx={{
        backgroundColor: theme.custom.main,
        borderColor: theme.custom.border,
        height: "100%",
      }}
    >
      <>
        <CardContent>
          <Typography
            gutterBottom
            sx={{
              color: theme.custom.text,
              fontSize: 14,
            }}
          >
            Account breakdown
          </Typography>
          <AccountsBreakdown
            accounts={accounts}
            expenseScheduleData={expenseScheduleData}
            incomeScheduleData={incomeScheduleData}
          />
        </CardContent>
        <CardActions>
          <Button
            sx={{ marginLeft: "auto", mr: 1 }}
            size="small"
            variant="outlined"
            color="secondary"
            onClick={onUpdateBalances}
          >
            Update balances
          </Button>
        </CardActions>
      </>
    </Card>
  );
}

export default AccountsBreakdownCard;
