import { useCallback, useEffect, useState } from "react";
import axiosInstance from "../../utils/axiosConfig";

export interface Expense {
  id: string;
  accountId: string;
  accountName: string;
  amount: number;
  createdAt: string;
  date: string;
  interval: "yearly" | "monthly" | "weekly" | "once"; // | "daily" | "yearly"
  name: string;
  userId: string;
}

const useLoadExpenses = ({ userId }: { userId: string }) => {
  const [data, setData] = useState<Expense[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const fetchData = useCallback(async () => {
    try {
      const response = await axiosInstance.get<Expense[]>("/api/expenses", {
        params: { userId },
      });
      setData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("Failed to fetch data.");
    } finally {
      setLoading(false);
    }
  }, [userId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return { data, error, loading, refetch: fetchData };
};

export default useLoadExpenses;
