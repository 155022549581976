import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  useTheme,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { forwardRef } from "react";
import { Account } from "../../hooks/accounts/useLoadAccounts";

interface Props {
  account: Account;
  deleteLoading: boolean;
  handleDelete: (accountId: string) => void;
  onClose: () => void;
}

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

function DeleteAccountDialog({
  account,
  deleteLoading,
  handleDelete,
  onClose,
}: Props) {
  const theme = useTheme();

  return (
    <Dialog
      open={true}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      aria-describedby="alert-dialog-slide-description"
      maxWidth="sm"
      fullWidth
      sx={{ ".MuiPaper-root": { backgroundColor: theme.custom.dialogBg } }}
    >
      <DialogTitle>Delete account</DialogTitle>
      <DialogContent dividers>
        Are you sure you want to delete the account: {account.name}?
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="secondary"
          disabled={deleteLoading}
          onClick={() => handleDelete(account.id)}
        >
          {deleteLoading ? "Deleting account" : "Delete account"}
        </Button>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

export default DeleteAccountDialog;
