import {
  Box,
  Card,
  CardContent,
  Checkbox,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import useCreatePaidExpense from "../../hooks/expenses/useCreatePaidExpense";
import useDeletePaidExpense from "../../hooks/expenses/useDeletePaidExpense";
import { ScheduledExpense } from "../../hooks/expenses/useLoadExpensesSchedule";

interface Props {
  data: ScheduledExpense[];
  refetch: () => Promise<void>;
}

function ExpensesCard({ data, refetch }: Props) {
  const theme = useTheme();

  const isSmall = useMediaQuery("(max-width:600px)");
  const [hidePaid, setHidePaid] = useState(false);
  const [sortKey, setSortKey] = useState<"date" | "amount" | "account">("date");
  const [expensesList, setExpensesList] = useState(data);

  const { mutation: createMutation, loading: isCreating } =
    useCreatePaidExpense();
  const { mutation: deleteMutation, loading: isDeleting } =
    useDeletePaidExpense();

  useEffect(() => {
    const updatedExpenses = data
      .filter((d) => {
        if (hidePaid) {
          return !d.isPaid;
        } else {
          return true;
        }
      })
      .sort((a, b) => {
        if (sortKey === "date") {
          return dayjs(a.dueDate).valueOf() - dayjs(b.dueDate).valueOf();
        } else if (sortKey === "amount") {
          return b.amount - a.amount;
        } else {
          return a.accountName.localeCompare(b.accountName);
        }
      });
    setExpensesList(updatedExpenses);
  }, [data, hidePaid, sortKey]);

  const handleTogglePaid = async (expense: ScheduledExpense) => {
    if (expense.isPaid) {
      await deleteMutation(expense.paymentId);
    } else {
      await createMutation({ ...expense, isPaid: true });
    }

    await refetch();
  };

  const handleHidePaid = () => {
    setHidePaid((prev) => !prev);
  };

  const handleSort = (key: "amount" | "date" | "account") => {
    setSortKey(key);
  };

  return (
    <Card
      variant="outlined"
      sx={{
        backgroundColor: theme.custom.main,
        borderColor: theme.custom.border,
        height: "100%",
      }}
    >
      <CardContent>
        <Box display="flex">
          <Typography
            gutterBottom
            sx={{
              color: theme.custom.text,
              fontSize: 14,
            }}
          >
            Planned expenses
          </Typography>
          <Box
            sx={{
              marginLeft: "auto",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                color: theme.custom.text,
                fontSize: 12,
              }}
            >
              Hide paid
            </Typography>
            <Switch
              checked={hidePaid}
              onChange={handleHidePaid}
              color="secondary"
            />
          </Box>
        </Box>
        {/* // allow the user to dismiss an expense */}
        <TableContainer
          sx={{
            backgroundColor: theme.custom.main,
            boxShadow: "none",
            color: theme.custom.text,
          }}
          component={Paper}
        >
          <Table sx={{ color: theme.custom.text }} aria-label="simple table">
            <TableHead sx={{ color: theme.custom.text }}>
              <TableRow>
                <TableCell sx={{ color: theme.custom.text }}>Name</TableCell>
                <TableCell
                  sx={{ color: theme.custom.text, cursor: "pointer" }}
                  align="right"
                  onClick={() => handleSort("amount")}
                >
                  Amount
                </TableCell>
                <TableCell
                  sx={{ color: theme.custom.text, cursor: "pointer" }}
                  align="right"
                  onClick={() => handleSort("date")}
                >
                  Due date
                </TableCell>
                <TableCell
                  sx={{ color: theme.custom.text, cursor: "pointer" }}
                  align="right"
                  onClick={() => handleSort("account")}
                >
                  Account
                </TableCell>
                <TableCell sx={{ color: theme.custom.text }} align="right">
                  Paid
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {expensesList.map((row) => (
                <TableRow
                  key={row.paymentId}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell
                    sx={{ color: theme.custom.text }}
                    component="th"
                    scope="row"
                  >
                    {row.name}
                  </TableCell>
                  <TableCell sx={{ color: theme.custom.text }} align="right">
                    £{row.amount}
                  </TableCell>
                  <TableCell sx={{ color: theme.custom.text }} align="right">
                    {dayjs(row.dueDate).format("dddd DD/MM/YYYY")}
                  </TableCell>
                  <TableCell sx={{ color: theme.custom.text }} align="right">
                    {row.accountName}
                  </TableCell>
                  <TableCell sx={{ color: theme.custom.text }} align="right">
                    <Checkbox
                      edge="end"
                      size={isSmall ? "large" : "medium"}
                      color="secondary"
                      onChange={() =>
                        !isCreating && !isDeleting && handleTogglePaid(row)
                      }
                      checked={row.isPaid}
                      sx={{ color: theme.custom.text }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
}

export default ExpensesCard;
