import {
  Card,
  CardContent,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import dayjs from "dayjs";
import { useMemo } from "react";
import { Income } from "../../hooks/incomes/useLoadIncomes";

interface Props {
  data: Income[];
}

function IncomesCard({ data }: Props) {
  const theme = useTheme();

  const completeIncomes = useMemo(
    () =>
      data
        .map((d) => ({
          ...d,
          dueIn: dayjs(d.date).diff(dayjs(), "days"),
        }))
        .sort(
          (a, b) => dayjs(a.date).millisecond() - dayjs(b.date).millisecond()
        ),
    [data]
  );

  return (
    <Card
      variant="outlined"
      sx={{
        backgroundColor: theme.custom.main,
        borderColor: theme.custom.border,
        height: "100%",
      }}
    >
      <CardContent>
        <Typography
          gutterBottom
          sx={{
            color: theme.custom.text,
            fontSize: 14,
          }}
        >
          Planned income
        </Typography>
        <TableContainer
          sx={{
            backgroundColor: theme.custom.main,
            boxShadow: "none",
            color: theme.custom.text,
          }}
          component={Paper}
        >
          <Table sx={{ color: theme.custom.text }} aria-label="simple table">
            <TableHead sx={{ color: theme.custom.text }}>
              <TableRow>
                <TableCell sx={{ color: theme.custom.text }}>Name</TableCell>
                <TableCell sx={{ color: theme.custom.text }} align="right">
                  Amount
                </TableCell>
                <TableCell sx={{ color: theme.custom.text }} align="right">
                  Date
                </TableCell>
                <TableCell sx={{ color: theme.custom.text }} align="right">
                  Account
                </TableCell>
                <TableCell sx={{ color: theme.custom.text }} align="right">
                  Due in
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {completeIncomes.map((row) => (
                <TableRow
                  key={row.name}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell
                    sx={{ color: theme.custom.text }}
                    component="th"
                    scope="row"
                  >
                    {row.name}
                  </TableCell>
                  <TableCell sx={{ color: theme.custom.text }} align="right">
                    £{row.amount}
                  </TableCell>
                  <TableCell sx={{ color: theme.custom.text }} align="right">
                    {dayjs(row.date).format("dddd DD/MM/YYYY")}
                  </TableCell>
                  <TableCell sx={{ color: theme.custom.text }} align="right">
                    {row.accountName}
                  </TableCell>
                  <TableCell sx={{ color: theme.custom.text }} align="right">
                    {row.dueIn < 0
                      ? "Assumed as received"
                      : `${row.dueIn} days`}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
}

export default IncomesCard;
