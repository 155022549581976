import { useMediaQuery, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { useLocation } from "react-router-dom";
import BottomNav from "./components/layout/BottomNav";
import Drawer from "./components/layout/Drawer";
import { useAuthContext } from "./context/AuthContext";

function Layout({ children }: { children: JSX.Element }) {
  const theme = useTheme();
  const isSmall = useMediaQuery("(max-width:600px)");
  const { user } = useAuthContext();
  const location = useLocation();

  const isNotFoundPage = location.pathname === "/404";

  if (isNotFoundPage || !user) {
    return children;
  }

  return (
    <Box sx={{ pb: 7 }}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        {!isSmall && <Drawer />}
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            backgroundColor: theme.custom.bg,
            maxWidth: "100%",
          }}
        >
          {children}
        </Box>
      </Box>
      {isSmall && <BottomNav />}
    </Box>
  );
}

export default Layout;
