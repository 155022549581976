import { useCallback, useState } from "react";
import axiosInstance from "../../utils/axiosConfig";
import { Theme } from "./useThemes";

const useCreateTheme = () => {
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const mutation = useCallback(async (themeConfig: Omit<Theme, "id">) => {
    try {
      setLoading(true);
      await axiosInstance.post<Theme>("/api/themes", themeConfig);
    } catch (error) {
      console.error("Error posting data:", error);
      setError("Failed to post data.");
    } finally {
      setLoading(false);
    }
  }, []);

  return { error, loading, mutation };
};

export default useCreateTheme;
