import { Card, CardContent, Typography, useTheme } from "@mui/material";

interface Props {
  accountsTotal: number;
  accountsClosingTotal: number;
}

function TotalCard({ accountsTotal, accountsClosingTotal }: Props) {
  const theme = useTheme();

  return (
    <Card
      variant="outlined"
      sx={{
        backgroundColor: theme.custom.main,
        borderColor: theme.custom.border,
        height: "100%",
      }}
    >
      <>
        <CardContent>
          <Typography
            gutterBottom
            sx={{
              color: theme.custom.text,
              fontSize: 14,
            }}
          >
            Total from all accounts
          </Typography>
          <Typography
            variant="h3"
            component="div"
            sx={{ color: theme.custom.text, mb: 2 }}
          >
            £{accountsTotal}
          </Typography>
          <Typography sx={{ color: theme.custom.text, fontSize: 12 }}>
            Closing balance
          </Typography>
          <Typography
            variant="h4"
            component="div"
            sx={{ color: theme.custom.text }}
          >
            £{accountsClosingTotal}
          </Typography>
        </CardContent>
      </>
    </Card>
  );
}

export default TotalCard;
