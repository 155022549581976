import { Dayjs } from "dayjs";
import { useCallback, useEffect, useState } from "react";
import axiosInstance from "../../utils/axiosConfig";

export interface ScheduledExpense {
  amount: number;
  dueDate: string;
  expenseId: string;
  paidDate: Dayjs | null;
  paymentId: string;
  userId: string;
  name: string;
  accountName: string;
  accountId: string;
  isPaid: boolean;
}

const useLoadExpensesSchedule = ({
  from,
  to,
  userId,
}: {
  from: string | null;
  to: string | null;
  userId: string;
}) => {
  const [data, setData] = useState<ScheduledExpense[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const fetchData = useCallback(async () => {
    try {
      const response = await axiosInstance.get<ScheduledExpense[]>(
        "/api/expenses-schedule",
        {
          params: {
            from,
            to,
            userId,
          },
        }
      );
      setData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("Failed to fetch data.");
    } finally {
      setLoading(false);
    }
  }, [from, to]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return { data, error, loading, refetch: fetchData };
};

export default useLoadExpensesSchedule;
