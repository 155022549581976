import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Slide,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { forwardRef, useState } from "react";
import { Account } from "../../hooks/accounts/useLoadAccounts";

interface Props {
  handlePost: (account: Pick<Account, "amount" | "name">) => void;
  postLoading: boolean;
  postError: string | null;
  onClose: () => void;
}

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

function AddAccountDialog({
  handlePost,
  onClose,
  postError,
  postLoading,
}: Props) {
  const theme = useTheme();

  const [newAccount, setNewAccount] = useState<
    Pick<Account, "amount" | "name">
  >({
    amount: 0,
    name: "",
  });

  return (
    <Dialog
      open={true}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      aria-describedby="alert-dialog-slide-description"
      maxWidth="sm"
      fullWidth
      sx={{ ".MuiPaper-root": { backgroundColor: theme.custom.dialogBg } }}
    >
      <DialogTitle>Add account</DialogTitle>
      <DialogContent dividers>
        <FormControl fullWidth sx={{ mb: 4 }} variant="outlined">
          <TextField
            label="Account name"
            variant="outlined"
            fullWidth
            onChange={(event) =>
              setNewAccount({ ...newAccount, name: event.target.value })
            }
          />
        </FormControl>
        <FormControl fullWidth>
          <InputLabel>Amount</InputLabel>
          <OutlinedInput
            startAdornment={<InputAdornment position="start">£</InputAdornment>}
            label="Amount"
            fullWidth
            type="number"
            onChange={(event) =>
              setNewAccount({
                ...newAccount,
                amount: Number(event.target.value) ?? 0,
              })
            }
          />
        </FormControl>
        {postError && (
          <Typography variant="caption" color="error">
            {postError}
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="secondary"
          disabled={postLoading}
          onClick={() => handlePost(newAccount)}
        >
          {postLoading ? "Adding account" : "Add account"}
        </Button>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddAccountDialog;
