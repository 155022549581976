import { createTheme, Theme, ThemeOptions } from "@mui/material";

export const mainColor = "#2c3345";
export const bgColor = "#2e3443";
export const darkBgColor = "#212329";
export const borderColor = "#4e5361";
export const textColor = "#a2a4a7";
export const accentColor = "#7e6792";
export const alertBackgroundColor = "#b0bfe4";
export const statusColorMap = {
  good: "#429442",
  warning: "#947242",
  danger: "#944242",
};

const themeConfig: ThemeOptions = {
  palette: {
    primary: {
      light: "#00",
      main: mainColor,
      dark: "#000",
      contrastText: "#fff",
    },
    secondary: {
      light: "#000",
      main: accentColor,
      dark: mainColor,
      contrastText: textColor,
    },
  },
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: mainColor,
          borderRight: `1px solid ${borderColor}`,
          padding: "0 20px",
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: "none",
        },
      },
    },
  },
};

export const theme: Theme = createTheme(themeConfig);
