import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  OutlinedInput,
  Slide,
  Typography,
  useTheme,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { forwardRef, useState } from "react";
import { defaultTheme } from "../../context/UserThemeContext";
import { Theme } from "../../hooks/themes/useThemes";

interface Props {
  error: string | null;
  loading: boolean;
  userId: string;
  onCreateTheme: (themeConfig: Theme) => void;
  onClose: () => void;
}

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

function CreateThemeDialog({
  error,
  loading,
  userId,
  onCreateTheme,
  onClose,
}: Props) {
  const theme = useTheme();

  const [newTheme, setNewTheme] = useState<Theme>({
    ...defaultTheme,
    label: "",
    owner: userId,
  });

  return (
    <Dialog
      open={true}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      aria-describedby="alert-dialog-slide-description"
      maxWidth="sm"
      fullWidth
      sx={{ ".MuiPaper-root": { backgroundColor: theme.custom.dialogBg } }}
    >
      <DialogTitle>Create new theme</DialogTitle>
      <DialogContent dividers>
        <FormControl fullWidth sx={{ mb: 4 }}>
          <InputLabel>Accent colour</InputLabel>
          <OutlinedInput
            label="Accent colour"
            type="color"
            fullWidth
            value={newTheme.accent}
            onChange={(event) =>
              setNewTheme({ ...newTheme, accent: event.target.value })
            }
          />
        </FormControl>
        <FormControl fullWidth sx={{ mb: 4 }}>
          <InputLabel>Main colour</InputLabel>
          <OutlinedInput
            label="Main colour"
            type="color"
            fullWidth
            value={newTheme.main}
            onChange={(event) =>
              setNewTheme({ ...newTheme, main: event.target.value })
            }
          />
        </FormControl>
        <FormControl fullWidth sx={{ mb: 4 }}>
          <InputLabel>Text colour</InputLabel>
          <OutlinedInput
            label="Text colour"
            type="color"
            fullWidth
            value={newTheme.text}
            onChange={(event) =>
              setNewTheme({ ...newTheme, text: event.target.value })
            }
          />
        </FormControl>
        <FormControl fullWidth sx={{ mb: 4 }}>
          <InputLabel>Background colour</InputLabel>
          <OutlinedInput
            label="Background colour"
            type="color"
            fullWidth
            value={newTheme.bg}
            onChange={(event) =>
              setNewTheme({ ...newTheme, bg: event.target.value })
            }
          />
        </FormControl>
        <FormControl fullWidth sx={{ mb: 4 }}>
          <InputLabel>Border colour</InputLabel>
          <OutlinedInput
            label="Border colour"
            type="color"
            fullWidth
            value={newTheme.border}
            onChange={(event) =>
              setNewTheme({ ...newTheme, border: event.target.value })
            }
          />
        </FormControl>
        <FormControl fullWidth sx={{ mb: 4 }}>
          <InputLabel>Dialog background colour</InputLabel>
          <OutlinedInput
            label="Dialog background colour"
            type="color"
            fullWidth
            value={newTheme.dialogBg}
            onChange={(event) =>
              setNewTheme({ ...newTheme, dialogBg: event.target.value })
            }
          />
        </FormControl>
        <FormControl fullWidth sx={{ mb: 4 }}>
          <InputLabel>Theme name</InputLabel>
          <OutlinedInput
            label="Theme name"
            type="text"
            fullWidth
            value={newTheme.label}
            onChange={(event) =>
              setNewTheme({ ...newTheme, label: event.target.value })
            }
          />
        </FormControl>
        {error && (
          <Typography variant="caption" color="error">
            {error}
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="secondary"
          disabled={loading}
          onClick={() => onCreateTheme(newTheme)}
        >
          {loading ? "Creating theme" : "Create theme"}
        </Button>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

export default CreateThemeDialog;
