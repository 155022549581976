import { useCallback, useState } from "react";
import axiosInstance from "../../utils/axiosConfig";

const apiUrl = process.env.REACT_APP_API_URL;
console.log("apiUrl:", apiUrl);
// https://api-moshueesfq-uc.a.run.app/
// localhost:3001

const useSignIn = () => {
  const [data, setData] = useState<{
    data: {
      email: string | null;
      uid: string;
    };
  } | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const mutation = useCallback(
    async ({ email, password }: { email: string; password: string }) => {
      try {
        setLoading(true);
        const response = await axiosInstance.post(
          // `${apiUrl}/api/authenticate/sign-in`,
          "/api/authenticate/sign-in",
          {
            email,
            password,
          }
        );

        setData(response.data);
      } catch (error) {
        console.error("Error posting data:", error);
        setError("Failed to post data.");
      } finally {
        setLoading(false);
      }
    },
    []
  );

  return { data, error, loading, mutation };
};

export default useSignIn;
