import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./styles.css";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
dayjs.extend(advancedFormat); // Extend dayjs with the advancedFormat plugin

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  // <React.StrictMode>
  <App />
  // </React.StrictMode>
);
