import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import {
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import dayjs from "dayjs";
import { useState } from "react";
import AddIncomeDialog from "../components/incomes/AddIncomeDialog";
import DeleteIncomeDialog from "../components/incomes/DeleteIncomeDialog";
import EditIncomeDialog from "../components/incomes/EditIncomeDialog";
import { useAuthContext } from "../context/AuthContext";
import useLoadAccounts from "../hooks/accounts/useLoadAccounts";
import useCreateIncome from "../hooks/incomes/useCreateIncome";
import useDeleteIncome from "../hooks/incomes/useDeleteIncome";
import useLoadIncomes, { Income } from "../hooks/incomes/useLoadIncomes";
import useUpdateIncome from "../hooks/incomes/useUpdateAccount";

function IncomesPage() {
  const theme = useTheme();
  const { user } = useAuthContext();

  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [selectedIncome, setSelectedIncome] = useState<Income | null>(null);

  const { data, error, loading, refetch } = useLoadIncomes({
    userId: user?.uid || "",
  });
  const { data: accountsData } = useLoadAccounts({ userId: user?.uid || "" });

  const { loading: deletingIncome, mutation: deleteIncome } = useDeleteIncome();
  const {
    loading: updatingIncome,
    error: editError,
    mutation: updateIncome,
  } = useUpdateIncome();

  const {
    error: postError,
    loading: postLoading,
    mutation,
  } = useCreateIncome();

  const handlePost = async (
    income: Pick<Income, "accountId" | "amount" | "interval" | "name"> & {
      dayOfMonth: number;
    }
  ) => {
    const currentDate = new Date();
    currentDate.setDate(income.dayOfMonth);
    if (user?.uid) {
      await mutation({
        userId: user.uid,
        date: currentDate.toISOString(),
        ...income,
      });
    }
    setOpen(false);
    await refetch();
  };

  const handleConfirmDeleteIncome = async (income: Income) => {
    setSelectedIncome(income);
    setOpenDelete(true);
  };

  const handleDeleteIncome = async (accountId: string) => {
    await deleteIncome(accountId);
    setOpenDelete(false);
    await refetch();
  };

  const handleEditIncome = (income: Income) => {
    setSelectedIncome(income);
    setOpenEdit(true);
  };

  const handleConfirmEditIncome = async (
    id: string,
    patch: Pick<Income, "accountId" | "amount" | "interval" | "name"> & {
      dayOfMonth: number;
    }
  ) => {
    const currentDate = new Date();
    currentDate.setDate(patch.dayOfMonth);

    if (user?.uid) {
      await updateIncome({
        userId: user.uid,
        id,
        ...patch,
        date: currentDate.toISOString(), // last to ensure override
      });
    }

    setOpenEdit(false);
    await refetch();
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (postLoading) {
    return <div>Posting...</div>;
  }

  // todo
  // opt into 'rules' - ie. if weekending on a weekend, move to the next / previous working day
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} display="flex">
          <Button
            sx={{ marginLeft: "auto" }}
            variant="contained"
            color="secondary"
            onClick={() => setOpen(true)}
          >
            Add income
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Card
            variant="outlined"
            sx={{
              backgroundColor: theme.custom.main,
              borderColor: theme.custom.border,
              height: "100%",
            }}
          >
            <CardContent>
              <Typography
                gutterBottom
                sx={{
                  color: theme.custom.text,
                  fontSize: 14,
                }}
              >
                Income
              </Typography>
              <TableContainer
                sx={{
                  backgroundColor: theme.custom.main,
                  boxShadow: "none",
                  color: theme.custom.text,
                }}
                component={Paper}
              >
                <Table
                  sx={{ color: theme.custom.text }}
                  aria-label="simple table"
                >
                  <TableHead sx={{ color: theme.custom.text }}>
                    <TableRow>
                      <TableCell sx={{ color: theme.custom.text }}>
                        Name
                      </TableCell>
                      <TableCell sx={{ color: theme.custom.text }} align="left">
                        Interval
                      </TableCell>
                      <TableCell sx={{ color: theme.custom.text }} align="left">
                        Date
                      </TableCell>
                      <TableCell sx={{ color: theme.custom.text }} align="left">
                        Account
                      </TableCell>
                      <TableCell
                        sx={{ color: theme.custom.text }}
                        align="right"
                      >
                        Amount
                      </TableCell>
                      <TableCell
                        sx={{ color: theme.custom.text }}
                        width={"15%"}
                        align="right"
                      >
                        Actions
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.map((row) => (
                      <TableRow
                        key={row.name}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          sx={{ color: theme.custom.text }}
                          component="th"
                          scope="row"
                        >
                          {row.name}
                        </TableCell>
                        <TableCell sx={{ color: theme.custom.text }}>
                          {row.interval}
                        </TableCell>
                        <TableCell sx={{ color: theme.custom.text }}>
                          {dayjs(row.date).format("Do")}
                        </TableCell>
                        <TableCell sx={{ color: theme.custom.text }}>
                          {row.accountName}
                        </TableCell>
                        <TableCell
                          sx={{ color: theme.custom.text }}
                          align="right"
                        >
                          £{row.amount}
                        </TableCell>
                        <TableCell
                          sx={{ color: theme.custom.text }}
                          align="right"
                        >
                          <Tooltip title="Edit">
                            <IconButton
                              aria-label="edit"
                              size="small"
                              onClick={() => handleEditIncome(row)}
                              color="secondary"
                            >
                              <EditIcon fontSize="inherit" />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Delete">
                            <IconButton
                              aria-label="delete"
                              size="small"
                              onClick={() => handleConfirmDeleteIncome(row)}
                              color="secondary"
                            >
                              <DeleteForeverIcon fontSize="inherit" />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {open && (
        <AddIncomeDialog
          accounts={accountsData}
          onClose={() => setOpen(false)}
          postLoading={postLoading}
          postError={postError}
          handlePost={handlePost}
        />
      )}
      {openDelete && selectedIncome && (
        <DeleteIncomeDialog
          income={selectedIncome}
          deleteLoading={deletingIncome}
          handleDelete={handleDeleteIncome}
          onClose={() => {
            setOpenDelete(false);
            setSelectedIncome(null);
          }}
        />
      )}
      {openEdit && selectedIncome && (
        <EditIncomeDialog
          income={selectedIncome}
          accounts={accountsData}
          editLoading={updatingIncome}
          editError={editError}
          handleEdit={handleConfirmEditIncome}
          onClose={() => {
            setOpenEdit(false);
            setSelectedIncome(null);
          }}
        />
      )}
    </>
  );
}

export default IncomesPage;
