import { useMemo } from "react";

interface Props {
  data: { amount: number }[];
}

function useAccountsTotal({ data }: Props) {
  const total = useMemo(() => {
    return data.reduce((acc, { amount }) => acc + amount || 0, 0);
  }, [data]);

  return parseFloat(total.toFixed(2));
}

export default useAccountsTotal;
