import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Slide,
  Typography,
  useTheme,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { forwardRef, useState } from "react";
import { Account } from "../../hooks/accounts/useLoadAccounts";

interface Props {
  accountList: Account[];
  editLoading: boolean;
  editError: string | null;
  handleEdit: (
    patchList: { accountId: string; patch: Pick<Account, "amount"> }[]
  ) => void;
  onClose: () => void;
}

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

function UpdateAllAccountBalancesDialog({
  accountList,
  editLoading,
  editError,
  handleEdit,
  onClose,
}: Props) {
  const theme = useTheme();

  const [newBalanceList, setNewBalanceList] = useState<
    { accountId: string; name: string; patch: Pick<Account, "amount"> }[]
  >(
    accountList.map((account) => ({
      accountId: account.id,
      name: account.name,
      patch: { amount: account.amount },
    }))
  );

  return (
    <Dialog
      open={true}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      aria-describedby="alert-dialog-slide-description"
      maxWidth="sm"
      fullWidth
      sx={{ ".MuiPaper-root": { backgroundColor: theme.custom.dialogBg } }}
    >
      <DialogTitle>Edit balances</DialogTitle>
      <DialogContent dividers>
        {newBalanceList.map((balance, index) => (
          <FormControl sx={{ mb: 4 }} fullWidth key={index}>
            <InputLabel>Balance for {balance.name}</InputLabel>
            <OutlinedInput
              startAdornment={
                <InputAdornment position="start">£</InputAdornment>
              }
              label={`Balance for ${balance.name}`}
              type="number"
              fullWidth
              value={balance.patch.amount}
              onChange={(event) => {
                const newBalanceListCopy = [...newBalanceList];
                newBalanceListCopy[index] = {
                  accountId: balance.accountId,
                  name: balance.name,
                  patch: { amount: Number(event.target.value) ?? 0 },
                };
                setNewBalanceList(newBalanceListCopy);
              }}
            />
          </FormControl>
        ))}
        {editError && (
          <Typography variant="caption" color="error">
            {editError}
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="secondary"
          disabled={editLoading}
          onClick={() => handleEdit(newBalanceList)}
        >
          {editLoading ? "Updating balances" : "Update balances"}
        </Button>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

export default UpdateAllAccountBalancesDialog;
