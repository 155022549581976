import { useCallback, useState } from "react";
import axiosInstance from "../../utils/axiosConfig";
import { UserPreferences } from "./userLoadUserPreferences";

const useUpdateUserPreferences = () => {
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const mutation = useCallback(
    async (userPreferences: Partial<UserPreferences>) => {
      try {
        setLoading(true);
        await axiosInstance.patch("/api/user-preferences", userPreferences);
      } catch (error) {
        console.error("Error patching data:", error);
        setError("Failed to patch data.");
      } finally {
        setLoading(false);
      }
    },
    []
  );

  return { error, loading, mutation };
};

export default useUpdateUserPreferences;
