import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Slide,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { forwardRef, useState } from "react";
import { Account } from "../../hooks/accounts/useLoadAccounts";

interface Props {
  account: Account;
  editLoading: boolean;
  editError: string | null;
  handleEdit: (accountId: string, patch: Partial<Account>) => void;
  onClose: () => void;
}

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

function EditAccountDialog({
  account,
  editLoading,
  editError,
  handleEdit,
  onClose,
}: Props) {
  const theme = useTheme();

  const [newAccount, setNewAccount] = useState<
    Pick<Account, "amount" | "name">
  >({
    ...account,
  });

  return (
    <Dialog
      open={true}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      aria-describedby="alert-dialog-slide-description"
      maxWidth="sm"
      fullWidth
      sx={{ ".MuiPaper-root": { backgroundColor: theme.custom.dialogBg } }}
    >
      <DialogTitle>Edit account</DialogTitle>
      <DialogContent dividers>
        <FormControl fullWidth sx={{ mb: 4 }} variant="outlined">
          <TextField
            label="Account name"
            variant="outlined"
            fullWidth
            value={newAccount.name}
            onChange={(event) =>
              setNewAccount({ ...newAccount, name: event.target.value })
            }
          />
        </FormControl>
        <FormControl fullWidth>
          <InputLabel>Amount</InputLabel>
          <OutlinedInput
            startAdornment={<InputAdornment position="start">£</InputAdornment>}
            label="Amount"
            fullWidth
            type="number"
            value={newAccount.amount}
            onChange={(event) =>
              setNewAccount({
                ...newAccount,
                amount: Number(event.target.value) ?? 0,
              })
            }
          />
        </FormControl>
        {editError && (
          <Typography variant="caption" color="error">
            {editError}
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="secondary"
          disabled={editLoading}
          onClick={() => handleEdit(account.id, newAccount)}
        >
          {editLoading ? "Editing account" : "Edit account"}
        </Button>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

export default EditAccountDialog;
